import { nanoid } from 'nanoid';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { ReactComponent as AlertIcon, ReactComponent as AlertInfoIcon } from '../assets/icons/AlertIcon.svg';
import { ReactComponent as CrossIcon } from '../assets/icons/toast-close.svg';
import Button from '../components/common/button/button';
import { OrganisationContext } from '../context/organisationContext';
import { initModal } from '../helpers/utils';
import { useError } from '../hooks/useError.js';
import { addToast } from '../store/features/toastSlice';
import { deleteUser, inviteUsers, setUserStatus } from '../store/features/userManagementSlice';
import { UserActionsWrapper } from './modal.styled.js';

const UserActions = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { showErrorToast } = useError();

  const userOrganisation = useSelector(state => state.user.user.organizations);
  const { modal, setModal } = useContext(OrganisationContext);
  const { user, isDelete, isReinvite, onSuccess, title, description } = modal.content;

  const [loading, setLoading] = useState(false);

  const onConfirmAction = async () => {
    if (loading) return;
    setLoading(true);
    try {
      if (isReinvite) {
        dispatch(inviteUsers({ user: user, organization_id: userOrganisation[0].id }))
          .then(response => {
            dispatch(
              addToast({
                error: false,
                text: t('SUCCESSFULLY_USER_INVITED'),
                id: nanoid(),
              }),
            );
            dispatch(setUserStatus({ id: response.id, status: 'INVITED' }));
          })
          .catch(error => {
            const { data } = error?.response;
            showErrorToast({ error, default_message: t('ERROR_WHILE_RE_INVITE_USER') });
          });
        setModal(initModal);
      } else if (isDelete) {
        dispatch(deleteUser({ ...user })).catch(error => {
          showErrorToast({ error, default_message: t('ERROR_WHILE_DELETING_USER') });
        });
        setModal(initModal);
      }
    } catch (error) {
      setLoading(false);
      const text = `Failed to ${isDelete ? 'delete' : 'disable'} Integration`;
      showErrorToast({ error, default_message: text });
    }
  };

  return (
    <CSSTransition appear classNames="popup-fade" in timeout={300}>
      <UserActionsWrapper className="flex-column items-center pxy-6 gap-8">
        <div className="flex-column gap-5 content-wrapper items-center relative">
          {isDelete ? <AlertIcon /> : <AlertInfoIcon />}
          <CrossIcon className="absolute right-0 cursor" onClick={() => setModal(initModal)} />
          <div className="flex-column gap-2 items-center justify-start w-full">
            <p className=" font-18 text-center inter-500-text natural-900-text">{title}</p>
            {description && <span className="inter-400-text font-14 natural-500-text text-center">{description}</span>}
          </div>
        </div>
        <div className="px-3 py-4 flex-column gap-3 w-full radius-1_5 user-details-container">
          <div className="flex-column px-3 gap-0_5">
            <label className="inter-600-text font-16 natural-900-text">{user.name}</label>
            <label className="inter-400-text font-14 natural-500-text">{user?.role?.name}</label>
          </div>
          <div className="flex-column items-start row-gap-1 justify-start w-full">
            <div className="flex-column px-3 py-1_5 justify-start w-full gap-1">
              <label className="inter-400-text font-12 natural-400-text">{t('MOBILE_PRIMARY')}</label>
              <label className={`inter-400-text font-14 natural-900-text ${!user?.email ? 'grey-text' : ''}`}>
                {user?.phone ? user?.phone : 'Not provided'}
              </label>
            </div>
            <div className="flex-column px-3 py-1_5 justify-start w-full gap-1">
              <label className="inter-400-text font-12 natural-400-text">{t('EMAIL_PRIMARY')}</label>
              <label className={`inter-400-text font-14 natural-900-text ${!user?.phone ? 'grey-text' : ''}`}>
                {user?.email ? user?.email : 'Not provided'}
              </label>
            </div>
          </div>
        </div>
        <div className="action-wrapper flex-column justify-center w-full gap-3">
          <Button
            className={`primary ${loading && 'disabled'} ${isDelete ? 'delete-btn' : ''}`}
            disabled={loading}
            label={`${isReinvite ? 'Resend Invite' : isDelete ? t('DELETE') : t('CONFIRM')}`}
            onClick={onConfirmAction}
            primary={true}
            size="large"
            borderRadius="100px"
          />
          <Button
            className={`cancel-btn default ${loading && 'disabled'}`}
            label={t('CANCEL')}
            onClick={() => setModal(initModal)}
            size="large"
            borderRadius="100px"
          />
        </div>
      </UserActionsWrapper>
    </CSSTransition>
  );
};

export default UserActions;
