import { MenuItem } from '@szhsin/react-menu';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { OrganisationContext } from '../../context/organisationContext';
import { capitalize, formatText } from '../../helpers/utils';
import { useError } from '../../hooks/useError';
import { updateUsersList, updateUsersStatus } from '../../store/features/userManagementSlice';
import { UsersListItemWrapper } from '../../styles/components/users-list/users-list.styled';
import Button from '../common/button/button';
import MenuV2 from '../common/menu/menu-v2';
import Switch from '../common/switch';

const UserListItem = ({ user, onEditUserProfile, menuPortal }) => {
  const { checkPermission, hasPermission, setModal } = useContext(OrganisationContext);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { showErrorToast } = useError();

  const onChangeStatus = async () => {
    const { is_active, id } = user;
    const params = {
      is_active: !is_active,
    };
    dispatch(updateUsersList({ id: id }));
    dispatch(updateUsersStatus({ id: id, params })).catch(error => {
      dispatch(updateUsersList({ id: id }));
      showErrorToast({ error, default_message: t('ERROR_UPDATE_STATUS') });
    });
  };

  const onResendClick = async () => {
    setModal({
      type: 'user-action',
      content: {
        user: user,
        top: true,
        isReinvite: true,
        title: t('RESEND_INVITE_USER'),
      },
    });
  };

  const onDeleteUser = async () => {
    setModal({
      type: 'user-action',
      content: {
        user: user,
        top: true,
        isDelete: true,
        title: t('ARE_YOU_SURE_TO_DELETE_THIS_USER'),
        description: t('DELETE_ACTION_DESCRIPTION'),
      },
    });
  };

  const getExtraMenuOption = () => {
    if (user.status === 'EXPIRED' || user.status === 'INVITED') {
      return (
        <Button
          fontSize="14px"
          size="medium"
          borderRadius="100px"
          className={`secondary w-full ${!hasPermission('USERS_MANAGE') && 'disabled'}`}
          label={t('RESEND_INVITE')}
          onClick={() => checkPermission(() => onResendClick(), 'USERS_MANAGE')}
        />
      );
    } else {
      return (
        <div className="flex items-center justify-between  w-full  extra-option">
          <span className={`inter-400-text color-neutral-900 flex items-center last-width enable-color font-14`}>
            {t('ACTIVE')}
          </span>
          <Switch
            onClick={() => checkPermission(() => onChangeStatus(), 'USERS_MANAGE')}
            enabled={user?.is_active}
            disabled={false}
            switchWrapperClassName="toggle-btn"
          />
        </div>
      );
    }
  };

  useEffect(() => {
    getExtraMenuOption();
  }, [user.status, user.is_active]);

  return (
    <UsersListItemWrapper className="w-full cursor sidebar-close-ignore" onClick={e => onEditUserProfile(user)}>
      <div className="flex-column items-start overflow-hidden justify-center w-full">
        <label className="color-neutral-900 font-14 inter-500-text one-line">{user?.name}</label>
        <label className="color-gray-secondary font-14 inter-400-text mt-1 one-line">{user?.role?.name}</label>
      </div>

      <div className="flex-column items-start overflow-hidden justify-center w-full">
        <div className="flex items-center justify-start w-full">
          <label className={`color-neutral-900 font-14 inter-400-text one-line ${!user.email ? 'grey-text' : ''}`}>
            {user?.email ? user?.email : t('NOT_PROVIDED')}
          </label>
        </div>
        <div className="flex items-center justify-start w-full mt-1">
          <label className={`color-neutral-900 font-14 inter-400-text one-line ${!user.phone ? 'grey-text' : ''}`}>
            {user?.phone ? user?.phone : t('NOT_PROVIDED')}
          </label>
        </div>
      </div>

      <div className="flex items-start gap-3 overflow-hidden justify-start w-full wrap">
        {user?.teams.map(data => (
          <div className="flex items-center justify-center w-auto" key={data?.id}>
            <label className="color-light-black-1 font-12 bg-lightgray-1 radius-6 pxy-2-10 inter-500-text">
              {data?.name}
            </label>
          </div>
        ))}
      </div>

      <div className="flex items-center justify-between w-full">
        <div className={`flex items-center justify-center pxy-2-10 radius-6 w-auto  ${formatText(user?.status)}`}>
          <label className="inter inter-500-text font-12">{capitalize(formatText(user?.status), '')}</label>
        </div>
        <MenuV2
          menuList={[
            { name: t('DETAILS'), onClick: () => onEditUserProfile(user), permission: 'USERS_MANAGE' },
            { name: t('DELETE'), onClick: () => onDeleteUser(), permission: 'USERS_MANAGE' },
            ...(user.status === 'EXPIRED' || user.status === 'INVITED' ? [] : []),
            // : [{ name: t('AVAILBILITY'), onClick: () => { }, permission: 'USERS_MANAGE' }]
          ]}
          iconClassName="rotate-90 dots-icon"
          ExtraOptions={<MenuItem style={{ background: '#fff' }}>{getExtraMenuOption()}</MenuItem>}
          portal={menuPortal}
        />
      </div>
    </UsersListItemWrapper>
  );
};

export default UserListItem;
