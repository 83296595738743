import classNames from 'classnames';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { OrganisationContext } from '../../context/organisationContext';
import { capitalize, formatText, getFormattedDateV2 } from '../../helpers/utils';
import { AutomationLogItemWrapper } from '../../styles/components/automation-logs-list/automation-logs-list.styled';
import MenuV2 from '../common/menu/menu-v2';

const AutomationLogItem = ({ automationLog, menuPortal }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { setModal } = useContext(OrganisationContext);

  const onAutomationLog = () => {
    navigate(`/platforms/automation-logs/${automationLog.id}`);
  };

  const onCancel = () => {
    setModal({
      type: 'automation-cancel',
      content: { automationLog },
    });
  };

  return (
    <AutomationLogItemWrapper
      className="data-container items-center justify-center w-full py-0"
      onClick={() => onAutomationLog()}>
      <div className="brd-right flex items-cente w-full">
        <label className="inter-500-text color-neutral-900 ont-14 one-line">{automationLog?.id}</label>
      </div>
      <div className="brd-right flex items-center w-full overflow-hidden">
        <label className="inter-400-text color-neutral-900 font-14 one-line">{automationLog?.automation?.name}</label>
      </div>
      <div className="brd-right flex items-center w-full overflow-hidden">
        <label className="inter-400-text color-neutral-900 font-14 one-line">
          {automationLog?.created_on
            ? getFormattedDateV2({
                dateInUnix: automationLog?.created_on,
                format: 'DD/MM/YY - hh:mm a',
              })
            : '-'}
        </label>
      </div>
      <div className="flex items-center justify-between w-full">
        <div className="flex items-center flex-1">
          <div
            className={classNames(
              'flex items-center justify-center radius-6 status-container',
              formatText(automationLog?.status, ''),
            )}>
            <label className="inter-500-text color-neutral-900 font-12 one-line">
              {capitalize(formatText(automationLog?.status, ''))}
            </label>
          </div>
        </div>
      </div>
      <div>
        <MenuV2
          menuList={[
            { name: t('DETAILS'), onClick: () => onAutomationLog(), permission: 'AUTOMATION_LOGS_VIEW' },
            automationLog?.status === 'PENDING' && { name: t('CANCEL'), onClick: () => onCancel() },
          ].filter(Boolean)}
          iconClassName="rotate-90 dots-icon"
          portal={menuPortal}
        />
      </div>
    </AutomationLogItemWrapper>
  );
};

export default AutomationLogItem;
