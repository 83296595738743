import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as AutomationLogsIcon } from '../../assets/icons/automation-logs.svg';
import { ReactComponent as AutomationsIcon } from '../../assets/icons/automations.svg';
import { ReactComponent as DashboardIcon } from '../../assets/icons/dashboard.svg';
import { ReactComponent as IntegrationsIcon } from '../../assets/icons/integrations.svg';
import { ReactComponent as MultiUsersIcon } from '../../assets/icons/multi-users.svg';
import { ReactComponent as PlatformsIcon } from '../../assets/icons/platforms.svg';
import { ReactComponent as InvoiceIcon } from '../../assets/icons/report.svg';
import { ReactComponent as SettingsIcon } from '../../assets/icons/settings.svg';
import { ReactComponent as SitesHomeIcon } from '../../assets/icons/sites-home.svg';
import { ReactComponent as TransactionIcon } from '../../assets/icons/tarrif-icon.svg';
import MainLayout from '../../components/main-layout';
import { OrganisationContext } from '../../context/organisationContext';
import { getUsersPermissions, isUserSassOperator } from '../../helpers/utils';
import Modal from '../../popup';
import history from '../../routes/history';
import { ROUTE_PATHS } from '../../routes/route-paths';
import { addToast } from '../../store/features/toastSlice';
import { MainContentWrapper } from '../../styles/pages/main-content.styled';

const Main = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { toasts } = useSelector(state => state.toast);
  const { user } = useSelector(state => state.user);

  const [modal, setModal] = useState({
    type: null,
    content: {},
  });
  const [insideModal, setInsideModal] = useState({
    type: null,
    content: {},
  });
  const [sideModal, setSideModal] = useState({
    type: null,
    content: {},
  });

  const permissions = useMemo(() => {
    return getUsersPermissions();
  }, [user]);

  const hasPermission = permission => {
    return permissions?.includes(permission);
  };

  const hasMultiplePermission = permissionList => {
    return (
      !permissionList ||
      !!(permissionList.length > 0 && permissionList.find(permission => permissions?.includes(permission)))
    );
  };

  const [selectedPath, setSelectedPath] = useState(location.pathname);

  const checkPermission = (action, permission) => {
    if (!permission || permissions.includes(permission)) {
      if (action) action();
    } else
      !toasts.find(t => t.id === 'PERMISSION_ERROR') &&
        dispatch(
          addToast({
            id: 'PERMISSION_ERROR',
            error: true,
            text: t('YOU_DO_NOT_HAVE_PERMISSION_TO_PERFORM_THIS_ACTION'),
          }),
        );
  };

  const items = useMemo(
    () => [
      {
        id: 'DASHBOARD',
        icon: DashboardIcon,
        iconSelected: DashboardIcon,
        text: t('DASHBOARD'),
        redirect: '/dashboard',
        selected: location.pathname === '/dashboard',
        subitems: [],
        hasPermission: true,
        permissionFunc: () => true,
        // hasPermission: hasMultiplePermission(['DASHBOARD_VIEW']),
        // permissionFunc: () => hasMultiplePermission(['DASHBOARD_VIEW']),
      },
      {
        id: 'SITES',
        icon: SitesHomeIcon,
        iconSelected: SitesHomeIcon,
        text: t('SITES'),
        redirect: '/sites',
        selected: location.pathname === '/sites',
        subitems: [],
        hasPermission: hasMultiplePermission(['SITES_VIEW']),
        permissionFunc: () => hasMultiplePermission(['SITES_VIEW']),
      },
      {
        id: 'ACCOUNTS',
        icon: MultiUsersIcon,
        text: t('ACCOUNTS'),
        redirect: '/accounts',
        selected: location.pathname === '/accounts',
        subitems: [],
        hasPermission: hasMultiplePermission(['ACCOUNTS_VIEW']) && isUserSassOperator(),
        permissionFunc: () => hasMultiplePermission(['ACCOUNTS_VIEW']) && isUserSassOperator(),
      },
      {
        id: 'CUSTOMERS',
        icon: MultiUsersIcon,
        iconSelected: MultiUsersIcon,
        text: t('CUSTOMERS'),
        redirect: '/customers',
        selected: location.pathname === '/customers',
        subitems: [],
        hasPermission: hasMultiplePermission(['CONTACTS_VIEW']),
        permissionFunc: () => hasMultiplePermission(['CONTACTS_VIEW']),
      },
      {
        id: 'BILLS',
        icon: InvoiceIcon,
        iconSelected: InvoiceIcon,
        text: t('BILLS'),
        redirect: '/bills',
        selected: location.pathname === '/bills',
        subitems: [],
        hasPermission: hasMultiplePermission(['INVOICES_VIEW']),
        permissionFunc: () => hasMultiplePermission(['INVOICES_VIEW']),
      },
      {
        id: 'TRANSACTIONS',
        icon: TransactionIcon,
        iconSelected: TransactionIcon,
        text: t('TRANSACTIONS'),
        redirect: '/transactions',
        selected: location.pathname === '/transactions',
        subitems: [],
        hasPermission: hasMultiplePermission(['TRANSACTIONS_VIEW']),
        permissionFunc: () => hasMultiplePermission(['TRANSACTIONS_VIEW']),
      },
      {
        id: 'PLATFORMS',
        icon: PlatformsIcon,
        iconSelected: PlatformsIcon,
        text: t('PLATFORMS'),
        redirect: '/platforms',
        selected: location.pathname === '/platforms',
        hasPermission: hasMultiplePermission(['INTEGRATIONS_VIEW', 'AUTOMATIONS_VIEW', 'AUTOMATION_LOGS_VIEW']),
        permissionFunc: () => hasMultiplePermission(['INTEGRATIONS_VIEW', 'AUTOMATIONS_VIEW', 'AUTOMATION_LOGS_VIEW']),
        subitems: [
          {
            id: 0,
            text: t('INTEGRATIONS'),
            redirect: '/platforms/integrations',
            selected: location.pathname.startsWith('/platforms/integrations'),
            icon: IntegrationsIcon,
            hasPermission: hasMultiplePermission(['INTEGRATIONS_VIEW']),
            permissionFunc: () => hasMultiplePermission(['INTEGRATIONS_VIEW']),
          },
          {
            id: 1,
            text: t('AUTOMATION'),
            redirect: '/platforms/automations',
            selected: location.pathname.startsWith('/platforms/automations'),
            icon: AutomationsIcon,
            hasPermission: hasMultiplePermission(['AUTOMATIONS_VIEW']),
            permissionFunc: () => hasMultiplePermission(['AUTOMATIONS_VIEW']),
          },
          {
            id: 3,
            text: t('AUTOMATION_LOGS'),
            redirect: '/platforms/automation-logs',
            selected: location.pathname.startsWith('/platforms/automation-logs'),
            icon: AutomationLogsIcon,
            hasPermission: hasMultiplePermission(['AUTOMATION_LOGS_VIEW']),
            permissionFunc: () => hasMultiplePermission(['AUTOMATION_LOGS_VIEW']),
          },
        ],
      },
      {
        id: 'SETTINGS',
        icon: SettingsIcon,
        iconSelected: SettingsIcon,
        text: t('SETTINGS'),
        redirect: '/settings',
        selected: location.pathname === '/settings',
        subitems: [],
        hasPermission: hasMultiplePermission(['USERS_VIEW']),
        permissionFunc: () => hasMultiplePermission(['USERS_VIEW']),
      },
    ],
    [location.pathname, permissions],
  );

  const [menuItems, setMenuItems] = useState(items);

  const onItemClick = (itemId, subitemId) => {
    const item = menuItems.find(item => item.id === itemId);
    if (item) {
      if (item.subitems.length > 0) {
        if (subitemId !== 0 && !subitemId)
          setMenuItems(
            menuItems.map(menuItem => (menuItem.id === itemId ? { ...menuItem, open: !menuItem.open } : menuItem)),
          );
        else {
          navigate(item.subitems.find(subitem => subitem.id === subitemId).redirect);
        }
      } else {
        navigate(item.redirect);
      }
    }
  };

  useEffect(() => {
    if (selectedPath) {
      setMenuItems(
        menuItems.map(menuItem =>
          selectedPath.startsWith(menuItem.redirect)
            ? {
                ...menuItem,
                hasPermission: menuItem?.permissionFunc && menuItem?.permissionFunc(),
                open: menuItem.subitems.length > 0,
                selected: true,
                subitems: menuItem.subitems.map(subitem => ({
                  ...subitem,
                  hasPermission: subitem?.permissionFunc && subitem?.permissionFunc(),
                  selected: selectedPath.startsWith(subitem.redirect),
                })),
              }
            : {
                ...menuItem,
                hasPermission: menuItem?.permissionFunc && menuItem?.permissionFunc(),
                selected: false,
                open: false,
                subitems: menuItem.subitems.map(subitem => ({
                  ...subitem,
                  selected: false,
                })),
              },
        ),
      );
    }
  }, [selectedPath, permissions, user]);

  useEffect(() => {
    if (history.action === 'POP' || history.action === 'REPLACE' || history.action === 'PUSH') {
      setSelectedPath(location.pathname);
    }
  }, [location]);

  useEffect(() => {
    navigator.serviceWorker.getRegistrations().then(regs => regs.forEach(reg => reg.update()));
  }, [location]);

  const checkMultiplePermission = (action, permissionList) => {
    if (
      !permissionList ||
      (permissionList.length > 0 && permissionList.find(permission => permissions.includes(permission)))
    ) {
      if (action) action();
    } else
      !toasts.find(t => t.id === 'PERMISSION_ERROR') &&
        dispatch(
          addToast({
            id: 'PERMISSION_ERROR',
            error: true,
            text: t('YOU_DO_NOT_HAVE_PERMISSION_TO_PERFORM_THIS_ACTION'),
          }),
        );
  };

  const filteredRoutes = ROUTE_PATHS.filter(
    ({ permission, otherPermissionFunc }) =>
      hasMultiplePermission(permission) && (otherPermissionFunc ? otherPermissionFunc() : true),
  );
  const defaultRoute = filteredRoutes.find(
    ({ permission, otherPermissionFunc }) =>
      hasMultiplePermission(permission) && (otherPermissionFunc ? otherPermissionFunc() : true),
  );

  return (
    <MainContentWrapper>
      <OrganisationContext.Provider
        value={{
          modal,
          setModal,
          insideModal,
          setInsideModal,
          checkPermission,
          hasPermission,
          hasMultiplePermission,
          checkMultiplePermission,
          sideModal,
          setSideModal,
        }}>
        <MainLayout menuItems={menuItems} onItemClick={onItemClick} selectedPath={selectedPath} user={user}>
          <div className="main">
            <div className="content">
              <Routes>
                {filteredRoutes.map(path => (
                  <Route
                    key={path.id}
                    element={<path.Component {...path.params} path={path.path} {...path.actions} />}
                    path={path.path}
                    index
                  />
                ))}
                <Route element={<Navigate replace to={defaultRoute ? defaultRoute.path : '/settings'} />} path="*" />
              </Routes>
            </div>
          </div>
          <Modal />
        </MainLayout>
      </OrganisationContext.Provider>
    </MainContentWrapper>
  );
};

export default Main;
