import { useQuery } from '@tanstack/react-query';
import { getInvoiceDetails } from '../apis/invoices';

export const useInvoiceDetails = ({ invoice_id, ...rest }) => {
  return useQuery({
    enabled: !!invoice_id,
    queryKey: ['invoiceDetails', { invoice_id }],
    queryFn: () => getInvoiceDetails({ invoice_id, ...rest }),
  });
};
