import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as NoDocumentIcon } from '../../assets/icons/empty-document.svg';
import { useError } from '../../hooks/useError';
import { getAutomationLogs, setAutomationLogsList } from '../../store/features/automationsSlice';
import { AutomationLogsListWrapper } from '../../styles/components/automation-logs-list/automation-logs-list.styled';
import InfiniteScrollV2 from '../common/infinite-scroll-v2';
import NoData from '../common/no-data';
import SkeletonTransition from '../common/skeleton-transition';
import TableHeader from '../common/table-header';
import AutomationLogItem from './automation-log-item';

const automationLogTableHeaders = [
  { name: 'ID', key: 'ID' },
  { name: 'AUTOMATION NAME', key: 'AUTOMATION NAME' },
  { name: 'DATE', key: 'DATE' },
  { name: 'STATUS', key: 'STATUS' },
  { name: '', key: 'MENU' },
];

const AutomationLogsList = ({ debouncedSearch = '' }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const pageRef = useRef(0);

  const { showErrorToast } = useError();

  const { automationLogsList } = useSelector(state => state.automations);
  const { content: automation, last } = automationLogsList || {};

  const [showLoading, setShowLoading] = useState(true);

  const fetchAutomationLogList = (page, merge = false, debouncedSearch, showLoading = true) => {
    setShowLoading(showLoading);
    dispatch(
      getAutomationLogs({
        storeData: true,
        merge,
        params: {
          page: page,
          size: 15,
          search: debouncedSearch,
        },
      }),
    )
      .then(() => {
        pageRef.current = page;
      })
      .catch(() => showErrorToast({ error, default_message: t('ERROR_WHILE_FETCHING_AUTOMATION_LOG') }))
      .finally(() => {
        setShowLoading(false);
      });
  };

  const fetchMoreData = () => {
    fetchAutomationLogList(pageRef.current + 1, true, debouncedSearch, false);
  };

  useEffect(() => {
    fetchAutomationLogList(0, false, debouncedSearch);
  }, [debouncedSearch]);

  useEffect(() => {
    return () => {
      dispatch(setAutomationLogsList({ data: {} }));
    };
  }, []);

  return (
    <AutomationLogsListWrapper className="flex-column flex-1  radius-1 mt-6 mx-6 overflow-hidden">
      <SkeletonTransition
        loading={showLoading}
        loaderClassName={'item flex items-center justify-center flex-1 mb-6'}
        height={'100%'}
        containerClassName="line-height-1 h-full w-full">
        {automation?.length ? (
          <div className="w-full card radius-1_5 border overflow-scroll">
            <div className="flex-column w-fit-content min-w-full h-full">
              <TableHeader headers={automationLogTableHeaders} />
              <InfiniteScrollV2 hasMore={!last} fetchMoreData={fetchMoreData}>
                {(automation || []).map((automationLog, index) => (
                  <AutomationLogItem
                    automationLog={automationLog}
                    key={automationLog.id}
                    menuPortal={automation?.length < 3}
                  />
                ))}
              </InfiniteScrollV2>
            </div>
          </div>
        ) : debouncedSearch ? (
          <NoData className="flex-1" title={t('NO_RESULTS_FOUND')} description={t('NO_RESULTS_DESCRIPTION')} />
        ) : (
          <div className="flex-column flex-1 items-center justify-center">
            <NoData
              title={t('NO_DATA')}
              className="search-terms"
              description={t('NO_AUTOMATION_LOGS_HERE')}
              EmptyIcon={NoDocumentIcon}
              iconClassName="relative"
            />
          </div>
        )}
      </SkeletonTransition>
    </AutomationLogsListWrapper>
  );
};

export default AutomationLogsList;
