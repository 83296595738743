import React from 'react';
import { PolarAngleAxis, RadialBar, RadialBarChart, ResponsiveContainer } from 'recharts';
import { useTheme } from 'styled-components';

const HalfRadicalChart = ({
  data = [{ value: 0 }],
  className,
  innerRadius = 125,
  outerRadius = 200,
  barSize = 14,
  fill,
  children,
}) => {
  const theme = useTheme();
  const barFill = fill || theme.primary_500;

  return (
    <ResponsiveContainer className={className} width="100%" height="100%">
      <RadialBarChart
        cx="50%"
        cy="80%"
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        barSize={barSize}
        data={data}
        startAngle={180}
        endAngle={0}>
        <PolarAngleAxis type="number" domain={[0, 100]} angleAxisId={0} tick={false} />
        <RadialBar
          minPointSize={8}
          background
          clockWise
          dataKey="value"
          cornerRadius={outerRadius / 2}
          fill={barFill}
        />
        {children}
      </RadialBarChart>
    </ResponsiveContainer>
  );
};

export default HalfRadicalChart;
