import React, { Fragment } from 'react';
import Skeleton from 'react-loading-skeleton';
import { getFormattedNumber } from '../../../../../helpers/utils';
import { useError } from '../../../../../hooks/useError';
import { useInvoiceDetails } from '../../../../../hooks/useInvoices';
import { cn } from '../../../../../lib/utils';
import Label from '../../../../common/label';

const ItemData = ({ item, showBorderTop }) => {
  const { name, qty, unit_price, total_net, taxation_scheme, total_tax, unit, total_amount } = item || {};
  const { rate: taxRate } = taxation_scheme || {};

  const formattedUnitPrice = getFormattedNumber(unit_price || 0);
  const formattedTotalNet = getFormattedNumber(total_net || 0);
  const formattedTotalTax = getFormattedNumber(total_tax || 0);
  const formattedTotalAmount = getFormattedNumber(total_amount || 0);

  return (
    <div
      className={cn('px-4 py-4 grid grid-cols-account-bills-invoice-items-grid gap-x-4', showBorderTop && 'border-t')}>
      <div />
      <div className="flex items-center px-2 py-1 rounded-[4px] bg-natural-100 w-full">
        <Label className="flex items-center justify-between w-full gap-x-1">
          <span className="inter-400-text natural-500-text">{name}:</span>
          <span className="inter-400-text">
            {qty} {unit} @ {formattedUnitPrice} - {formattedTotalNet}
          </span>
        </Label>
      </div>
      <div className="flex items-center px-2 py-1 rounded-[4px] bg-natural-100 w-full">
        <Label className="flex items-center justify-between w-full gap-x-1">
          <span className="inter-400-text natural-500-text">Tax:</span>
          <span className="inter-400-text">%{taxRate}</span>
        </Label>
      </div>
      <div className="flex items-center px-2 py-1 rounded-[4px] bg-natural-100 w-full">
        <Label className="flex items-center justify-between w-full gap-x-1">
          <span className="inter-400-text natural-500-text">Tax amount:</span>
          <span className="inter-400-text">{formattedTotalTax}</span>
        </Label>
      </div>
      <div className="flex items-center px-2 py-1 rounded-[4px] bg-natural-100 w-full">
        <Label className="flex items-center justify-between w-full gap-x-1">
          <span className="inter-400-text natural-500-text">Total:</span>
          <span className="inter-400-text">{formattedTotalAmount}</span>
        </Label>
      </div>
    </div>
  );
};

const BillInvoiceItems = ({ invoice }) => {
  const { id: invoiceId } = invoice || {};

  const { data: invoiceDetails, isFetching, error } = useInvoiceDetails({ invoice_id: invoiceId });
  const { line_items = [] } = invoiceDetails || {};

  useError({ error, default_message: 'Error while fetching bill invoice details' });

  const isItemsAvailable = line_items && line_items.length > 0;

  return (
    <Fragment>
      {isFetching && <Skeleton containerClassName="px-4 py-2 flex h-fit" height={60} />}
      {!isFetching && !isItemsAvailable && (
        <div className="flex items-center justify-center h-14 inter-400-text">No items available</div>
      )}
      {!isFetching && isItemsAvailable && (
        <div className="flex-col flex">
          {line_items.map(item => (
            <ItemData key={item.id} item={item} showBorderTop={true} />
          ))}
        </div>
      )}
    </Fragment>
  );
};

export default BillInvoiceItems;
