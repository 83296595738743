import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ReactComponent as ArrowRightIcon } from '../../../../../assets/icons/arrow-right.svg';
import { getMainTimeSelectors } from '../../../../../helpers/optionData';
import { getFormattedDateV2, getFormattedNumber, getFormattedNumberStyle } from '../../../../../helpers/utils';
import { useAccountDetails, useAccountEnergyRevenue } from '../../../../../hooks/useAccounts';
import { useBills } from '../../../../../hooks/useBills';
import { useError } from '../../../../../hooks/useError';
import { cn } from '../../../../../lib/utils';
import Button from '../../../../common/button/button';
import FlexBox from '../../../../common/flex-box/flex';
import IconContainer from '../../../../common/icon-container';
import Label from '../../../../common/label';
import MultiDateSelector from '../../../../common/multi-date-selector';
import SearchableDropdown from '../../../../common/searchable-dropdown';
import RevenueAggregation from './revenue-aggregation';

const dateUnits = [
  {
    label: 'Daily',
    value: 'DAY',
    unit: 'DAY',
    format: 'DD/MM/YYYY',
  },
  {
    label: 'Monthly',
    value: 'MONTH',
    unit: 'MONTH',
    format: 'MMMM',
  },
];

const BillingPeriodContent = ({ selectedBillingPeriod, setSelectedBillingPeriod, billingPeriods }) => {
  const tweakedTimeSelectors = useMemo(() => {
    const mainTimeSelectors = getMainTimeSelectors();
    return mainTimeSelectors.filter(({ value }) => value !== 'TODAY');
  }, []);

  const { start_date, end_date, momentUnitToApply, noOfDuration, value, id: periodId } = selectedBillingPeriod || {};
  const isBillingPeriod = value === 'BILLING_PERIOD';

  const setTimeSelector = timeSelector => {
    if (timeSelector.value === 'BILLING_PERIOD') {
      const firstBillingPeriod = billingPeriods.at(0);
      setSelectedBillingPeriod(firstBillingPeriod);
      return;
    }
    setSelectedBillingPeriod(timeSelector);
  };

  const onDateChangeLeft = () => {
    if (isBillingPeriod) {
      const currentIndex = billingPeriods.findIndex(({ id }) => id === periodId);
      const prevIndex = currentIndex - 1;
      const prevBillingPeriod = billingPeriods.at(prevIndex) || billingPeriods.at(-1);
      setSelectedBillingPeriod(prevBillingPeriod);
      return;
    }
    const new_start_date = moment
      .unix(start_date)
      .subtract(noOfDuration, momentUnitToApply)
      .startOf(momentUnitToApply)
      .unix();
    const new_end_date = moment
      .unix(end_date)
      .subtract(noOfDuration, momentUnitToApply)
      .endOf(momentUnitToApply)
      .unix();
    const new_start_date_iso = moment.unix(new_start_date).toISOString(true);
    const new_end_date_iso = moment.unix(new_end_date).toISOString(true);
    setSelectedBillingPeriod({
      ...selectedBillingPeriod,
      start_date: new_start_date,
      end_date: new_end_date,
      start_date_iso: new_start_date_iso,
      end_date_iso: new_end_date_iso,
    });
  };

  const onDateChangeRight = () => {
    if (isBillingPeriod) {
      const currentIndex = billingPeriods.findIndex(({ id }) => id === periodId);
      const nextIndex = currentIndex + 1;
      const nextBillingPeriod = billingPeriods.at(nextIndex) || billingPeriods.at(0);
      setSelectedBillingPeriod(nextBillingPeriod);
      return;
    }
    const new_start_date = moment
      .unix(start_date)
      .add(noOfDuration, momentUnitToApply)
      .startOf(momentUnitToApply)
      .unix();
    const new_end_date = moment.unix(end_date).add(noOfDuration, momentUnitToApply).endOf(momentUnitToApply).unix();
    const new_start_date_iso = moment.unix(new_start_date).toISOString(true);
    const new_end_date_iso = moment.unix(new_end_date).toISOString(true);
    setSelectedBillingPeriod({
      ...selectedBillingPeriod,
      start_date: new_start_date,
      end_date: new_end_date,
      start_date_iso: new_start_date_iso,
      end_date_iso: new_end_date_iso,
    });
  };

  const onDateChange = dates => {
    const { start_date, end_date } = dates;
    const noOfDuration = moment.unix(end_date).diff(moment.unix(start_date), 'day');
    const new_start_date_iso = moment.unix(start_date).toISOString(true);
    const new_end_date_iso = moment.unix(end_date).toISOString(true);
    const customTimeSelector = {
      ...dates,
      start_date_iso: new_start_date_iso,
      end_date_iso: new_end_date_iso,
      label: 'Custom',
      value: 'CUSTOM',
      noOfDuration,
      momentUnitToApply: 'day',
    };
    setSelectedBillingPeriod(customTimeSelector);
  };

  const onBillingPeriodChange = value => {
    setSelectedBillingPeriod(value);
  };

  return (
    <div className="flex items-center justify-end gap-x-4 px-1 py-1">
      <SearchableDropdown
        isCustomSearchable={false}
        isSearchable={false}
        defaultAdditional={{
          defaultOptions: [
            { label: 'Billing Period', value: 'BILLING_PERIOD' },
            {
              label: 'Custom',
              value: 'CUSTOM',
              start_date: moment().subtract(10, 'days').startOf('day').unix(),
              end_date: moment().endOf('day').unix(),
              start_date_iso: moment().subtract(10, 'days').startOf('day').toISOString(true),
              end_date_iso: moment().endOf('day').toISOString(true),
              momentUnitToApply: 'day',
              noOfDuration: 10,
            },
            ...tweakedTimeSelectors,
          ],
        }}
        value={selectedBillingPeriod}
        onChange={value => setTimeSelector(value)}
        className="w-48"
        customStyle={{
          control: { minHeight: '32px', maxHeight: '32px', width: '192px' },
          menuList: { maxHeight: '400px' },
        }}
      />
      <div className="flex items-center gap-x-2">
        <IconContainer
          Icon={ArrowRightIcon}
          iconContainerClassname="cursor rounded-full"
          iconHeight={14}
          iconWidth={14}
          backgroundColor="natural_100"
          iconClassName="transform rotate-180"
          onClick={onDateChangeLeft}
          disabled={!momentUnitToApply && !isBillingPeriod}
        />
        {isBillingPeriod ? (
          <SearchableDropdown
            isCustomSearchable={false}
            isSearchable={false}
            defaultAdditional={{
              defaultOptions: billingPeriods,
            }}
            value={selectedBillingPeriod}
            onChange={onBillingPeriodChange}
            getOptionLabel={option => option.option_label}
            getOptionValue={option => option.id}
            className="w-44"
            customStyle={{
              control: { minHeight: '32px', maxHeight: '32px', width: '180px', border: 'none' },
              menuList: { maxHeight: '400px' },
              valueContainer: { padding: '0 4px' },
              dropdownIndicator: { display: 'none' },
              menu: { minWidth: '230px', right: '-6px' },
            }}
          />
        ) : (
          <MultiDateSelector
            selectedDates={{ start_date: start_date, end_date: end_date }}
            setSelectedDates={dates => onDateChange(dates)}
            isCalendarIcon={false}
            className="!border-none !px-1"
          />
        )}
        <IconContainer
          Icon={ArrowRightIcon}
          iconContainerClassname="cursor rounded-full"
          iconHeight={14}
          iconWidth={14}
          backgroundColor="natural_100"
          iconClassName="transform"
          onClick={onDateChangeRight}
          disabled={!momentUnitToApply && !isBillingPeriod}
        />
      </div>
    </div>
  );
};

const UsageActionSelections = ({
  dateSelection,
  setDateSelection,
  selectedBillingPeriod,
  setSelectedBillingPeriod,
  billingPeriods,
}) => {
  return (
    <FlexBox className="justify-between flex-1 gap-x-4">
      <FlexBox>
        {dateUnits.map(unit => (
          <Button
            label={unit.label}
            key={unit.value}
            size="medium"
            onClick={() => setDateSelection(unit)}
            width="100px"
            borderRadius="6px"
            className={cn('transparent specified-width px-4 py-2 min', {
              secondary: dateSelection.value === unit.value,
            })}>
            {unit.label}
          </Button>
        ))}
      </FlexBox>
      <BillingPeriodContent
        selectedBillingPeriod={selectedBillingPeriod}
        setSelectedBillingPeriod={setSelectedBillingPeriod}
        billingPeriods={billingPeriods}
      />
    </FlexBox>
  );
};

const UsageRevenueInfo = ({ data, isFetching }) => {
  const { autoconsumption_kwh, autoconsumption_net_revenue, export_kwh, export_net_revenue, net_revenue } = data || {};
  const revenueInfoBricks = [
    { label: 'Total Consumption', value: getFormattedNumberStyle(autoconsumption_kwh, 'decimal', 0, 0), unit: 'kWh' },
    { label: 'Total Consumption Revenue', value: getFormattedNumber(autoconsumption_net_revenue || 0) },
    { label: 'Total Export', value: getFormattedNumberStyle(export_kwh, 'decimal', 0, 0), unit: 'kWh' },
    { label: 'Total Export Revenue', value: getFormattedNumber(export_net_revenue || 0) },
    { label: 'Total Revenue', value: getFormattedNumber(net_revenue || 0) },
  ];

  return (
    <FlexBox className="gap-x-2 items-stretch">
      {revenueInfoBricks.map(({ label, value, unit }) => (
        <FlexBox
          key={label}
          className="flex items-start justify-between flex-wrap flex-1 flex-col gap-y-1 px-4 py-4 bg-natural-100 rounded-md">
          <Label className="inter-400-text natural-500-text">{label}</Label>
          <Label className="inter-600-text font-20" loading={isFetching}>
            {value} {unit || ''}
          </Label>
        </FlexBox>
      ))}
    </FlexBox>
  );
};

const getAllBillingPeriods = ({ bills }) => {
  return bills.reduce((acc, { start_date, end_date }) => {
    const start_date_unix = moment.unix(start_date).startOf('day').unix();
    const end_date_unix = moment.unix(end_date).endOf('day').unix();
    const formatted_start_date = getFormattedDateV2({ dateInUnix: start_date_unix, format: 'DD/MM/YYYY' });
    const formatted_end_date = getFormattedDateV2({ dateInUnix: end_date_unix, format: 'DD/MM/YYYY' });
    const option_label = `${formatted_start_date} - ${formatted_end_date}`;
    const start_date_iso = moment.unix(start_date_unix).toISOString(true);
    const end_date_iso = moment.unix(end_date_unix).toISOString(true);

    acc.push({
      label: 'Billing Period',
      value: 'BILLING_PERIOD',
      id: `${start_date_unix}_${end_date_unix}`,
      option_label: option_label,
      start_date: start_date_unix,
      end_date: end_date_unix,
      start_date_iso: start_date_iso,
      end_date_iso: end_date_iso,
    });
    return acc;
  }, []);
};

const getCurrentBillingPeriod = ({ billing_period }) => {
  const { current_period_start, current_period_end } = billing_period || {};
  const start_date_unix = moment(current_period_start).startOf('day').unix();
  const end_date_unix = moment(current_period_end).endOf('day').unix();
  const formatted_start_date = getFormattedDateV2({ dateInUnix: start_date_unix, format: 'DD/MM/YYYY' });
  const formatted_end_date = getFormattedDateV2({ dateInUnix: end_date_unix, format: 'DD/MM/YYYY' });
  const option_label = `${formatted_start_date} - ${formatted_end_date}`;
  const start_date_iso = moment.unix(start_date_unix).toISOString(true);
  const end_date_iso = moment.unix(end_date_unix).toISOString(true);

  return {
    label: 'Billing Period',
    value: 'BILLING_PERIOD',
    id: `${start_date_unix}_${end_date_unix}`,
    option_label: option_label,
    start_date: start_date_unix,
    end_date: end_date_unix,
    start_date_iso: start_date_iso,
    end_date_iso: end_date_iso,
  };
};

const AccountUsages = () => {
  const { account_id } = useParams();

  const { data: accountData, isLoading: accountDataLoading } = useAccountDetails({ account_id });
  const { data: billsList } = useBills({ enabled: !!account_id, params: { account_id, size: 100 } });

  const { billing: billing_period } = accountData || {};

  const [billingPeriods, setBillingPeriods] = useState([]);
  const [selectedBillingPeriod, setSelectedBillingPeriod] = useState({});
  const [dateSelection, setDateSelection] = useState(dateUnits[0]);

  const { start_date_iso, end_date_iso } = selectedBillingPeriod || {};
  const { unit } = dateSelection || {};

  useEffect(() => {
    if (billing_period) {
      const currentBillingPeriod = getCurrentBillingPeriod({ billing_period });
      const allBillingPeriods = getAllBillingPeriods({ bills: billsList || [] });
      setBillingPeriods([currentBillingPeriod, ...allBillingPeriods]);
      setSelectedBillingPeriod(currentBillingPeriod);
    }
  }, [billsList, billing_period]);

  const { data, isFetching, error } = useAccountEnergyRevenue({
    account_id,
    enabled: !(accountDataLoading || !start_date_iso || !end_date_iso),
    params: { start_date: start_date_iso, end_date: end_date_iso, unit },
  });
  useError({ error, default_message: 'Failed to fetch account energy revenue' });

  const { time_aggregation } = data || {};
  const time_aggregation_data = time_aggregation?.map(({ date, ...rest }) => ({
    ...rest,
    date: date ? moment(date).unix() : null,
  }));

  return (
    <div className="flex flex-col flex-1 gap-y-6 h-full overflow-hidden pt-2">
      <div className="flex justify-between">
        <UsageActionSelections
          dateSelection={dateSelection}
          setDateSelection={setDateSelection}
          selectedBillingPeriod={selectedBillingPeriod}
          setSelectedBillingPeriod={setSelectedBillingPeriod}
          billingPeriods={billingPeriods}
        />
      </div>
      <UsageRevenueInfo data={data} isFetching={isFetching} />
      <RevenueAggregation
        dateSelection={dateSelection}
        time_aggregation={time_aggregation_data}
        isFetching={isFetching}
      />
    </div>
  );
};

export default AccountUsages;
