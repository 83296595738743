import isEqual from 'lodash.isequal';
import { nanoid } from 'nanoid';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { ReactComponent as RightArrow } from '../../../../assets/icons/arrow-right.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/delete.svg';
import { capitalize, formatText } from '../../../../helpers/utils';
import {
  getIntegrationActionConfig,
  getLexiconListOptions,
  getTemplateListOptions,
} from '../../../../store/features/automationsSlice';
import IconContainer from '../../../common/icon-container';
import InputElement from '../../../common/input';
import Dropdown from '../../../common/select-dropdown';
import CustomTooltip from '../../../common/tooltip-new';

const defaultAttachmentOptions = [
  {
    label: 'Appointments ics',
    name: 'Appointments ics',
    id: 'appointment.icalendar',
    value: 'appointment.icalendar',
    shortcode: 'appointment.icalendar',
  },
];

const getInitialConfigDetailValues = integrationConfigValues => {
  return (integrationConfigValues || [])
    .filter(c => c.param_type !== 'GROUP')
    .map(param => ({
      param_name: param.param_type,
      param_value: param.param_value,
      display_name: param.display_value,
    }));
};

const getInitialGroupConfigValues = (config, configValue) => {
  const { id, param_type, params, ...rest } = config || {};
  const allValues = configValue?.filter(c => c.param_value === id) || [];

  const values = allValues.map(value => ({
    ...value,
    ...rest,
    param_id: id,
    id,
    index: value.param_value,
    params: params.map(p => ({
      ...p,
      param_value: value.params?.find(c => c.param_type === p.id)?.param_value,
      display_value: value.params?.find(c => c.param_type === p.id)?.display_value,
      ...((p.param_type === 'GROUP' && getInitialGroupConfigValues(p, value.params)) || {}),
    })),
  }));
  return { [id]: values };
};

const getDataFromGroupConfigs = (config, allValues) => {
  const { id } = config || {};

  const data = allValues.reduce((acc, value) => {
    const { param_value, params } = value;
    const groupParams = params.filter(p => p.param_type === 'GROUP');
    const individualParams = params.filter(p => p.param_type !== 'GROUP');

    const individialParamsData = individualParams
      .map(p => ({
        param_type: p.id,
        param_value: p.param_value,
        display_value: p.display_value,
      }))
      .filter(p => p.param_value);

    const groupParamsData = groupParams.map(p => getDataFromGroupConfigs(p, p?.[p.id] || [])).flat();

    const filteredGroupParamsData = [...groupParamsData].filter(p => p.params?.length);

    return [
      ...acc,
      {
        param_type: 'GROUP',
        param_value: id,
        display_value: id,
        params: [...individialParamsData, ...filteredGroupParamsData],
      },
    ];
  }, []);

  return data;
};

const getRequiredParamsTooltipContent = config => {
  const { required_params } = config;
  const paramsText = required_params.map(param => capitalize(formatText(param, ' '))).join(', ');
  return `You need to select ${paramsText} first`;
};

export const ListComponent = ({
  forLexicon = false,
  integration_id,
  action_type,
  configDetailValues = [],
  config,
  onChange,
  value,
  error = false,
}) => {
  const dispatch = useDispatch();
  const { id, required_params = [] } = config;

  const [options, setOptions] = useState([]);

  const isAllRequiredParamsAvailable = useMemo(() => {
    return required_params.length > 0
      ? required_params.every(param => {
          return configDetailValues.find(config => config.param_name === param);
        })
      : true;
  }, [required_params, configDetailValues]);

  const getOptions = async () => {
    const paramsValues = required_params.reduce((acc, param) => {
      const value = configDetailValues.find(config => config.param_name === param);
      acc[param] = value?.param_value;
      return acc;
    }, {});

    if (forLexicon) {
      dispatch(
        getLexiconListOptions({
          lexicon: id,
          params: paramsValues,
        }),
      )
        .then(({ data }) => {
          const responseData = data?.map(item => ({ ...item, value: item.id, label: item.name }));
          setOptions(responseData);
        })
        .catch(err => {
          setOptions([]);
        });
    } else {
      dispatch(
        getTemplateListOptions({
          integration_id: integration_id,
          actionType: action_type,
          paramId: id,
          params: paramsValues,
        }),
      )
        .then(({ data }) => {
          const responseData = data?.map(item => ({ ...item, value: item.id, label: item.name }));
          setOptions(responseData);
        })
        .catch(err => {
          setOptions([]);
        });
    }
  };

  useEffect(() => {
    if (isAllRequiredParamsAvailable && id) {
      getOptions();
    } else {
      setOptions([]);
    }
  }, [isAllRequiredParamsAvailable, id]);

  return (
    <div className="flex-column">
      <CustomTooltip
        hidden={isAllRequiredParamsAvailable}
        tooltipClassname="param-tooltip"
        content={<span className="inter-400-text font-12">{getRequiredParamsTooltipContent(config)}</span>}
        id={`tooltip-${config.id}`}>
        <Dropdown
          className={`integration-input-dropdown`}
          onChange={onChange}
          options={options}
          placeholder={'Select'}
          isSearchable={true}
          value={options?.find(c => c.id === value) || null}
          name={config.name}
          error={error}
          isDisabled={!isAllRequiredParamsAvailable}
        />
      </CustomTooltip>
    </div>
  );
};

const getConfigComponent = ({ param_type, onListChange, onInputChange, listValue, inputValue, ...rest }) => {
  switch (param_type) {
    case 'LIST':
      return <ListComponent {...rest} value={listValue} onChange={option => onListChange(option)} />;
    default:
      return <InputElement {...rest} value={inputValue} onChange={value => onInputChange(value)} />;
  }
};

const IndividualConfig = ({ integration_id, action_type, config, updateConfigValue, configValue }) => {
  const { name, param_type } = config;
  const { param_value, display_value } = configValue || {};

  return getConfigComponent({
    param_type,
    integration_id,
    action_type,
    config,
    updateConfigValue,
    configValue,
    placeholder: name,
    error: false,
    name: name,
    value: configValue,
    listValue: param_value ? param_value : null,
    inputValue: param_value,
    onInputChange: value => updateConfigValue({ param_value: value, display_value: value }),
    onListChange: option => updateConfigValue({ param_value: option.id, display_value: option.name }),
  });
};

const GroupChildConfig = ({
  integration_id,
  index,
  action_type,
  childConfig,
  updateConfigValue,
  groupConfigValue,
  onRemoveChild = () => {},
}) => {
  const { id, name, params } = childConfig;

  const [isOpened, setIsOpened] = useState(true);

  const groupParmas = params.filter(param => param.param_type === 'GROUP');
  const individualParams = params.filter(param => param.param_type !== 'GROUP');

  const updateIndividualConfigValue = (id, value) => {
    const updatedParams = params.map(param => {
      if (param.id === id) {
        return { ...param, ...value };
      }
      return param;
    });
    updateConfigValue(updatedParams);
  };

  const updateChildGroupConfigValue = (id, value) => {
    const updatedParams = params.map(param => {
      if (param.id === id) {
        return { ...param, [param.id]: value };
      }
      return param;
    });
    updateConfigValue(updatedParams);
  };

  return (
    <div className="flex-column border pxy-4 radius-2">
      <div className="flex items-center justify-between">
        <label className="inter-500-text natural-900-text">
          {name} {index + 1}
        </label>
        <div className="flex items-center">
          <IconContainer
            backgroundColor="transparent"
            Icon={DeleteIcon}
            onClick={onRemoveChild}
            iconContainerClassname="cursor"
          />
          <IconContainer
            backgroundColor="transparent"
            Icon={RightArrow}
            iconClassName={isOpened ? 'rotate-90' : ''}
            onClick={() => setIsOpened(!isOpened)}
            iconContainerClassname="cursor"
          />
        </div>
      </div>
      {isOpened && (
        <div className="flex-column row-gap-3 mt-2">
          <div className="config-grid items-center w-full">
            {individualParams.map(config => (
              <IndividualConfig
                key={config.id}
                config={config}
                updateConfigValue={value => updateIndividualConfigValue(config.id, value)}
                configValue={config}
                integration_id={integration_id}
                action_type={action_type}
              />
            ))}
          </div>
          {groupParmas.map(config => (
            <GroupConfig
              key={config.id}
              config={config}
              updateConfigValue={value => updateChildGroupConfigValue(config.id, value)}
              configValue={config?.[config.id] || []}
              integration_id={integration_id}
              action_type={action_type}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const GroupConfig = ({ integration_id, action_type, config, updateConfigValue, configValue = [] }) => {
  const { id, name, params, repeatable } = config;

  const [isOpened, setIsOpened] = useState(true);

  const isChildsAvailable = configValue?.length > 0;

  const onChildAdd = () => {
    const childsLength = configValue.length;
    const newConfigValue = [
      ...configValue,
      {
        index: childsLength + 1,
        id: nanoid(),
        param_value: childsLength + 1,
        name: name,
        param_type: id,
        params: params,
      },
    ];
    updateConfigValue(newConfigValue);
  };

  const updateChildConfigValue = (id, value) => {
    const newConfigValue = configValue.map(child => {
      if (child.id === id) {
        return { ...child, params: value };
      }
      return child;
    });
    updateConfigValue(newConfigValue);
  };

  const onRemoveChild = id => {
    const newConfigValue = configValue.filter(child => child.id !== id);
    updateConfigValue(newConfigValue);
  };

  const onRemoveAllChilds = () => {
    updateConfigValue([]);
  };

  return (
    <div className="flex-column row-gap-2">
      <div className="flex items-center justify-between">
        <label className="inter-500-text natural-900-text">{name}</label>
        <div className="flex items-center">
          <label className="inter-400-text error-text font-12" onClick={onRemoveAllChilds}>
            Remove all
          </label>
          <IconContainer
            backgroundColor="transparent"
            Icon={RightArrow}
            iconClassName={isOpened ? 'rotate-90' : ''}
            onClick={() => setIsOpened(!isOpened)}
            iconContainerClassname="cursor"
          />
        </div>
      </div>
      {isOpened && (
        <Fragment>
          {isChildsAvailable && (
            <div className="flex-column row-gap-4 mb-2">
              {configValue.map((childConfig, index) => (
                <GroupChildConfig
                  key={id}
                  index={index}
                  childConfig={childConfig}
                  updateConfigValue={value => updateChildConfigValue(childConfig.id, value)}
                  configValue={childConfig}
                  groupConfigValue={childConfig?.[childConfig.id]}
                  integration_id={integration_id}
                  action_type={action_type}
                  onRemoveChild={() => onRemoveChild(childConfig.id)}
                />
              ))}
            </div>
          )}
        </Fragment>
      )}
      {(repeatable || !isChildsAvailable) && (
        <div className="flex items-center cursor" onClick={onChildAdd}>
          <label className="inter-400-text primary-text">Add {name}</label>
        </div>
      )}
    </div>
  );
};

const IntegrationConfig = ({
  integration,
  action_type,
  setIntegrationData,
  integrationConfigValues,
  originalParams,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [integrationConfig, setIntegrationConfig] = useState([]);
  const [configDetails, setConfigDetails] = useState([]);
  const [groupConfigs, setGroupConfigs] = useState([]);
  const [configDetailValues, setConfigDetailValues] = useState(getInitialConfigDetailValues(integrationConfigValues));
  const [groupConfigValues, setGroupConfigValues] = useState({});
  const [errorFields, setErrorFields] = useState({});
  const [loading, setLoading] = useState(true);

  const fetchIntegrationConfig = () => {
    setLoading(true);
    dispatch(getIntegrationActionConfig({ integration_id: integration?.id, actionType: action_type }))
      .then(({ data }) => {
        setIntegrationConfig(data);
      })
      .catch(error => {
        setLoading(false);
        setConfigDetails([]);
        setGroupConfigs([]);
        setConfigDetailValues([]);
      });
  };

  useEffect(() => {
    fetchIntegrationConfig();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const configs = await Promise.all(
        integrationConfig?.map(async curr => {
          if (!curr.conditional) {
            if (curr?.param_type === 'LIST') {
              const filteredConfigs = configDetails.filter(item => item.param_type === 'LIST');
              const matchingConfig = filteredConfigs.find(config => config.id === curr.id);

              const { choices, params: prevParams } = matchingConfig || {};
              const params = getConfigParamValues(curr);
              const isBothSame = isEqual(params, prevParams);

              if (choices && isBothSame) {
                return {
                  ...curr,
                  params: prevParams,
                  choices: matchingConfig.choices,
                  value: getIntegrationParamValue(curr.id),
                  depends: await getConditional(curr),
                };
              } else {
                const { data, params } = await getOptions(curr);
                return {
                  ...curr,
                  choices: data,
                  params,
                  value: getIntegrationParamValue(curr.id),
                  depends: await getConditional(curr),
                };
              }
            } else if (curr?.param_type === 'ATTACHMENTS') {
              return {
                ...curr,
                choices: defaultAttachmentOptions,
                value: getIntegrationParamValue(curr.id),
                depends: await getConditional(curr),
              };
            } else {
              const data = await getConditional(curr);
              return { ...curr, value: getIntegrationParamValue(curr.id), depends: data };
            }
          }
          return null;
        }),
      );
      setLoading(false);
      const filteredConfigs = configs.filter(config => config !== null);
      const groupConfigs = filteredConfigs.filter(config => config.param_type === 'GROUP');
      const otherConfigs = filteredConfigs.filter(config => config.param_type !== 'GROUP');
      setConfigDetails(otherConfigs);
      setGroupConfigs(groupConfigs);
    };
    if (integrationConfig.length) {
      fetchData();
    } else {
      setLoading(false);
    }
  }, [integrationConfig, configDetailValues]);

  useEffect(() => {
    const initialGroupConfigValues = groupConfigs.reduce((acc, config) => {
      const values = getInitialGroupConfigValues(config, originalParams);
      return { ...acc, ...values };
    }, {});
    setGroupConfigValues(initialGroupConfigValues);
  }, [groupConfigs]);

  const getConfigParamValues = config => {
    const { required_params } = config;
    const paramsValues = required_params?.reduce((acc, param) => {
      const value = configDetailValues.find(config => config.param_name === param);
      acc[param] = value?.param_value;
      return acc;
    }, {});
    return paramsValues;
  };

  const isAllRequiredParamsAvailable = config => {
    const { required_params } = config;
    return required_params.length > 0
      ? required_params.every(param => {
          return configDetailValues.find(config => config.param_name === param);
        })
      : true;
  };

  const getOptions = curr => {
    const isAllDataAvailable = isAllRequiredParamsAvailable(curr);
    const paramsValues = getConfigParamValues(curr);

    if (!isAllDataAvailable) {
      return { data: [], params: paramsValues };
    }

    return new Promise((resolve, reject) => {
      dispatch(
        getTemplateListOptions({
          integration_id: integration?.id,
          actionType: action_type,
          paramId: curr?.id,
          params: paramsValues,
        }),
      )
        .then(response => {
          const responseData = response?.data?.map(item => ({ ...item, value: item.id, label: item.name }));
          resolve({ data: responseData, params: paramsValues });
        })
        .catch(err => {
          resolve({ data: [], params: paramsValues });
        });
    });
  };

  useEffect(() => {
    const finalData = groupConfigs.map(config => getDataFromGroupConfigs(config, groupConfigValues?.[config.id] || []));
    const filteredData = finalData.flat().filter(c => c.params?.length);
    setIntegrationData([...configDetailValues, ...filteredData]);
  }, [configDetailValues, groupConfigValues]);

  const getConditional = config => {
    const findCondition = integrationConfig.filter(c => c.conditional?.param_name === config.id);
    const finalData = findCondition.map(f => ({
      ...f,
      value: getIntegrationParamValue(f.id),
      depends: getConditional(f),
    }));
    return finalData;
  };

  const getIntegrationParamValue = name => {
    return configDetailValues?.find(param => param.param_name === name)?.param_value || '';
  };

  const updateIntegrationParams = (name, value, displayName) => {
    const isAvailable = configDetailValues?.find(param => param.param_name === name);
    if (isAvailable) {
      setConfigDetailValues([
        ...configDetailValues.map(param =>
          param.param_name === name ? { ...param, param_value: value, display_name: displayName?.name } : param,
        ),
      ]);
    } else {
      setConfigDetailValues([
        ...configDetailValues,
        { param_name: name, param_value: value, display_name: displayName?.name },
      ]);
    }
    setErrorFields({ ...errorFields, [name]: false });
  };

  const renderInput = (config, dependentConfig = null) => {
    return config.conditional ? (
      <>
        {config.conditional.param_value === dependentConfig?.value && (
          <div className="flex-column integration-input relative w-full">
            <div className="mb-1 one-line">
              <label className="inter-500-text natural-900-text">{config.name}</label>
            </div>
            <input
              autoComplete="turnoff"
              className={`input name-input-form w-full ${errorFields[config.id] && 'error-info'} ${
                config.param_type === 'PASSWORD' && !showPassword[config.id] && 'password-mask'
              }`}
              onChange={e => updateIntegrationParams(config.id, e.target.value)}
              placeholder={config.name}
              type="text"
              value={config.value || ''}
            />
            {config.param_type === 'PASSWORD' && (
              <div className="flex items-center cursor mr-16 input-show-img">
                <img
                  alt="icon"
                  onClick={() => setShowPassword({ [config.id]: !showPassword[config.id] })}
                  src={showPassword[config.id] ? ShowPassword : HidePassword}
                />
              </div>
            )}
          </div>
        )}
      </>
    ) : config.param_type === 'LIST' ? (
      <div className="flex-column">
        <CustomTooltip
          hidden={isAllRequiredParamsAvailable(config)}
          tooltipClassname="param-tooltip"
          content={<span className="inter-400-text font-12">{getRequiredParamsTooltipContent(config)}</span>}
          id={`tooltip-${config.id}`}>
          <Dropdown
            className={`integration-input-dropdown`}
            onChange={option => updateIntegrationParams(config.id, option.id, option)}
            options={config.choices || []}
            placeholder={'Select'}
            isSearchable={true}
            value={config.choices?.find(c => c.id === config.value) || null}
            name={config.name}
            error={errorFields[config.id]}
            isDisabled={!isAllRequiredParamsAvailable(config)}
          />
        </CustomTooltip>
      </div>
    ) : config.param_type === 'ATTACHMENTS' ? (
      <div className="flex-column">
        <Dropdown
          isMulti={true}
          className={`integration-input-dropdown`}
          onChange={option => updateIntegrationParams(config.id, option)}
          options={config.choices || []}
          placeholder={config.name}
          isSearchable={true}
          value={config.value || null}
          name={config.name}
          error={errorFields[config.id]}
        />
      </div>
    ) : config.param_type === 'GROUP' ? (
      <GroupConfig
        config={config}
        updateConfigValue={updateIntegrationParams}
        configValue={groupConfigValues}
        integration_id={integration?.id}
        action_type={action_type}
      />
    ) : (
      <div className="flex-column integration-input relative w-full">
        <div className="mb-1 one-line">
          <label className="inter-500-text natural-900-text">{config.name}</label>
        </div>
        <input
          autoComplete="turnoff"
          className={`input name-input-form w-full ${errorFields[config.id] && 'error-info'} ${
            config.param_type === 'PASSWORD' && !showPassword[config.id] && 'password-mask'
          }`}
          onChange={e => updateIntegrationParams(config.id, e.target.value)}
          placeholder={config.name}
          type="text"
          value={config.value || ''}
        />
        {config.param_type === 'PASSWORD' && (
          <div className="flex items-center cursor mr-16 input-show-img">
            <img
              alt="icon"
              onClick={() => setShowPassword({ [config.id]: !showPassword[config.id] })}
              src={showPassword[config.id] ? ShowPassword : HidePassword}
            />
          </div>
        )}
      </div>
    );
  };

  const renderConditionalConfigs = (config, dependentConfig = null, parentConfig = null) => {
    return (
      <>
        {(!config.conditional || (config.conditional && config.conditional.param_value === dependentConfig?.value)) && (
          <div className="flex-column action-container relative">
            <div className="flex-column">{renderInput(config, dependentConfig)}</div>
            {config.depends?.map(c => renderConditionalConfigs(c, config, dependentConfig ? parentConfig : config))}
          </div>
        )}
      </>
    );
  };

  return (
    <IntegrationConfigWrapper>
      {loading ? (
        <div className="config-grid items-center w-full">
          <Skeleton height={60} key={'skeleton-1'} />
          <Skeleton height={60} key={'skeleton-2'} />
          <Skeleton height={60} key={'skeleton-3'} />
          <Skeleton height={60} key={'skeleton-4'} />
          <Skeleton height={60} key={'skeleton-5'} />
          <Skeleton height={60} key={'skeleton-6'} />
        </div>
      ) : (
        <div className="flex-column row-gap-6">
          {(configDetails || []).length || (groupConfigs || []).length ? (
            <>
              <div className="config-grid items-center w-full">
                {configDetails.map(config => renderConditionalConfigs(config))}
              </div>
              {groupConfigs.map(config => (
                <GroupConfig
                  key={config.id}
                  config={config}
                  configValue={groupConfigValues?.[config.id]}
                  updateConfigValue={value => {
                    setGroupConfigValues({ ...groupConfigValues, [config.id]: value });
                  }}
                  integration_id={integration?.id}
                  action_type={action_type}
                />
              ))}
              {/* <div className="">
                <Editor
                  onChange={e => updateIntegrationParams('TAGS', e)}
                  getParmsValue={getIntegrationParamValue('TAGS')}
                />
              </div> */}
            </>
          ) : (
            <div className="flex items-center justify-center w-full">
              <label className="inter-400-text natural-400-text">{t('NO_INTEGRATIONS_PARAMS_FOUND')}</label>
            </div>
          )}
        </div>
      )}
    </IntegrationConfigWrapper>
  );
};

const IntegrationConfigWrapper = styled.div`
  .config-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 24px;
    column-gap: 16px;
  }

  .param-tooltip {
    padding: 8px 12px;
    box-shadow: 0px 2px 6px -4px rgba(16, 24, 40, 0.1), 0px 8px 15px -3px rgba(16, 24, 40, 0.1);
  }
`;

export default IntegrationConfig;
