import React from 'react';
import EnergyGeneration from './energy-generation';
import EnergyReading from './energy-reading';
import GenerationStats from './generation-stats';
import ThreeHourReading from './three-hour-readings';
import TopSites from './top-sites';

const Dashboard = () => {
  return (
    <div className="bg-natural-150 px-6 pt-5 flex flex-col gap-y-4 overflow-scroll custom-scrollbar thin-scrollbar">
      <GenerationStats />
      <div className="grid grid-cols-[1fr_320px] gap-4">
        <div className="flex flex-col gap-y-4">
          <ThreeHourReading />
          <EnergyReading />
        </div>
        <div className="flex flex-col gap-y-4">
          <EnergyGeneration />
          <TopSites />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
