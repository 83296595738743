import styled from 'styled-components';

export const MainContentWrapper = styled.div`
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  background: #ffffff;

  .main {
    max-width: calc(100% - 232px);
    max-height: calc(100dvh - 74px);
    width: 100%;
    overflow-y: auto;
    display: flex;

    .content {
      width: 100%;
      > div {
        height: 100%;
      }
    }
  }
`;
