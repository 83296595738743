import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { capitalize, formatText, getFormattedDateV2 } from '../../../helpers/utils';

const AutomatioLogDetailBox = () => {
  const { t } = useTranslation();
  const { automationLogDetails: automationLog } = useSelector(state => state.automations);
  return (
    <>
      <div className="flex items-center border-bottom px-3 pb-3">
        <label className="flex-1 font-18 inter-600-text natural-900-text">{t('AUTOMATION_LOG')}</label>
      </div>
      <div className="flex-column flex-1 mt-4">
        <div className="px-3 py-2 flex-column gap-0_5">
          <span className="font-12 inter-400-text natural-400-text line-height-20">{t('NAME')}</span>
          <p className="font-14 inter-400-text natural-900-text line-height-20">{automationLog?.automation?.name}</p>
        </div>
        <div className="px-3 py-2 flex-column gap-0_5 ">
          <span className="font-12 inter-400-text natural-400-text line-height-20">{t('ID')}</span>
          <p className="font-14 inter-400-text natural-900-text line-height-20">{automationLog?.automation?.id}</p>
        </div>
        <div className="px-3 py-2 flex-column gap-0_5 ">
          <span className="font-12 inter-400-text natural-400-text line-height-20">{t('DATE')}</span>
          <p className="font-14 inter-400-text natural-900-text line-height-20">
            {automationLog?.created_on
              ? getFormattedDateV2({
                  dateInUnix: automationLog?.created_on,
                  format: 'DD/MM/YY - hh:mm a',
                })
              : '-'}
          </p>
        </div>
        <div className="px-3 py-2 flex-column gap-0_5">
          <span className="font-12 inter-400-text natural-400-text line-height-20">{t('STATUS')}</span>
          <div
            className={`flex items-center justify-center radius-1  py-0_5 px-2_5 w-fit-content ${formatText(
              automationLog?.status,
              '',
            )}`}>
            <label className="medium-text font-12 one-line">{capitalize(formatText(automationLog?.status, ''))}</label>
          </div>
        </div>
      </div>
    </>
  );
};

export default AutomatioLogDetailBox;
