import { nanoid } from 'nanoid';
import { useDispatch } from 'react-redux';
import { addToast } from '../store/features/toastSlice';

export const useSuccess = props => {
  const { getSuccessTextFunc = null, toastMeta = {} } = props || {};
  const dispatch = useDispatch();

  const showToastFunc = ({ additionalMeta = {}, default_message = '', success = null }) => {
    const successText = getSuccessTextFunc ? getSuccessTextFunc(success) : default_message;
    dispatch(addToast({ ...toastMeta, ...additionalMeta, text: successText, error: false, id: nanoid() }));
  };

  return { showToastFunc };
};
