import classNames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import uuid from 'react-uuid';
import styled from 'styled-components';
import { ReactComponent as DisabledIcon } from '../../assets/icons/disabled_icon.svg';
import { ReactComponent as CrossIcon } from '../../assets/icons/toast-close.svg';

import { useTranslation } from 'react-i18next';
import Button from '../../components/common/button/button';
import { OrganisationContext } from '../../context/organisationContext';
import { capitalize, formatText, initModal } from '../../helpers/utils';
import { useError } from '../../hooks/useError';
import {
  deleteIntegration,
  getIntegrationAutomations,
  setIntegrationAutomations,
  updateIntegrationState,
} from '../../store/features/integrationsSlice';
import { addToast } from '../../store/features/toastSlice';

const IntegrationActions = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { showErrorToast } = useError();

  const { integrationAutomations } = useSelector(state => state.integrations);
  const { modal, setModal } = useContext(OrganisationContext);

  const [loading, setLoading] = useState(false);

  const { integration, isDelete, isDisable, onSuccess } = modal.content;

  const fetchIntegrationAutomations = integrationId => {
    dispatch(getIntegrationAutomations({ id: integrationId }));
  };

  useEffect(() => {
    if (integration.id) {
      fetchIntegrationAutomations(integration.id);
    }

    return () => {
      dispatch(setIntegrationAutomations([]));
    };
  }, [integration?.id]);

  const onConfirmAction = async () => {
    if (loading) return;
    setLoading(true);
    try {
      if (isDelete) {
        dispatch(deleteIntegration({ id: integration?.id })).then(data => {
          setLoading(false);
          setModal(initModal);
          dispatch(
            addToast({
              error: false,
              text: t('INTEGRATION_DELETED'),
              id: uuid(),
            }),
          );
          onSuccess && onSuccess();
        });
      } else {
        dispatch(
          updateIntegrationState({
            id: integration?.id,
            request: {
              enabled: false,
            },
            updateList: true,
          }),
        ).then(() => {
          setLoading(false);
          setModal(initModal);
          dispatch(
            addToast({
              error: false,
              text: t('INTEGRATION_DISABLE'),
              id: uuid(),
            }),
          );
          onSuccess && onSuccess();
        });
      }
    } catch (error) {
      setLoading(false);
      const text = `Failed to ${isDelete ? 'delete' : 'disable'} Integration`;
      showErrorToast({ error, default_message: text });
    }
  };

  const getOwnerLogo = (automation, index) => {
    const { owner } = automation;
    if (!owner.name) return '';
    const nameArray = owner.name?.split(' ');
    return (
      <div className={`owner-logo ml-3 pxy-2 owner-background-${index % 3}`}>
        <label className="bold-text">
          {nameArray?.[0]?.charAt(0)?.toUpperCase()}
          {nameArray?.[1]?.charAt(0)?.toUpperCase()}
        </label>
      </div>
    );
  };

  return (
    <CSSTransition appear classNames="popup-fade" in timeout={300}>
      <DeleteIntegrationWrapper className="flex-column items-center px-12 py-8">
        <div className="flex justify-center relative w-full">
          <div className="alert-icon">
            <DisabledIcon width={24} height={24} />
          </div>

          <CrossIcon width={20} height={20} className="closeIcon absolute cursor" onClick={() => setModal(initModal)} />
        </div>
        <p className="inter-500-text natural-900-text font-18 text-center mt-5">
          {isDelete ? 'Delete' : isDisable ? 'Disable' : ''} this Integration?
        </p>
        <div className="mt-6 py-4 px-5 flex-column w-full border integration-details-container">
          <label className="inter-600-text natural-900-text font-16">{integration.name}</label>
          <div className="flex items-center mt-2 integration-type-container">
            {integration.connector.icon?.active && (
              <div className="flex integration-icon">
                <img
                  alt="image"
                  className="mr-2"
                  src={`${integration.connector.icon?.active}`}
                  width={24}
                  height={24}
                />
              </div>
            )}
            <label className="inter-400-text natural-900-text">
              {capitalize(formatText(integration.connector?.name))}
            </label>
          </div>
        </div>
        {integrationAutomations?.length > 0 && (
          <div className="mt-6">
            <div className="flex items-center justify-center text-center">
              <label className="inter-400-text natural-700-text">{t('DELETING_THIS_INTEGRATION')}</label>
            </div>
            <div className="automation-detail mt-4 overflow-scroll">
              {integrationAutomations.map((automation, index) => (
                <div key={automation.id} className="py-4 px-3 border mb-3 automation-detail-container">
                  <div className="flex items-center justify-between integration-item-top">
                    <div className="flex-column mr-1 flex-1 integration-item-left">
                      <label className="inter-500-text natural-900-text hide-name font-16">{automation.name}</label>
                      <span className="inter-400-text natural-500-text mt-1">{automation.description}</span>
                    </div>
                    <div className="flex items-center integration-item-right" tabIndex={0}>
                      {getOwnerLogo(automation, index)}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        <div className="flex-column justify-between row-gap-3 mt-8 w-full">
          {isDelete ? (
            <Button
              className={classNames('negative', loading && 'disabled')}
              disabled={loading}
              label={t('DELETE')}
              onClick={onConfirmAction}
              size="large"
              borderRadius="100px"
            />
          ) : (
            <Button
              className={classNames('negative', loading && 'disabled')}
              disabled={loading}
              label={t('DISABLE')}
              onClick={onConfirmAction}
              size="large"
              borderRadius="100px"
            />
          )}
          <Button
            className={`default ${loading && 'disabled'}`}
            label={t('CANCEL')}
            onClick={() => setModal(initModal)}
            size="large"
            borderRadius="100px"
          />
        </div>
      </DeleteIntegrationWrapper>
    </CSSTransition>
  );
};

const DeleteIntegrationWrapper = styled.div`
  width: 450px;
  .alert-icon {
    display: flex;
    padding: 12px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 24px;
    background: #fef2f2;
  }

  .closeIcon {
    right: 0px;
  }
  .integration-details-container {
    border-radius: 6px;
  }

  .integration-type-container {
    .integration-icon {
      padding: 1px;
      background: white;
      border-radius: 4px;
    }
  }

  .automation-detail {
    max-height: min(calc(65vh - 350px), 350px);
    overflow: scroll;
  }

  .automation-detail-container {
    border-radius: 6px;

    .integration-item-top {
      .integration-item-left {
        .hide-name {
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          &:hover {
            display: block;
          }
        }
      }

      .integration-item-right {
        position: relative;
        outline: none;

        .owner-logo {
          border-radius: 50%;
        }

        .owner-background-0 {
          background: ${({ theme }) => theme.additionalRed2};
        }
        .owner-background-1 {
          background: ${({ theme }) => theme.additionalGreen};
        }
        .owner-background-2 {
          background: ${({ theme }) => theme.additionalYellow};
        }
      }
    }
  }
`;

export default IntegrationActions;
