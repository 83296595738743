import React, { useEffect, useMemo, useState } from 'react';
import { useTheme } from 'styled-components';
import { getMainTimeSelectors } from '../../helpers/optionData';
import { useGraphEnergyReadingData } from '../../hooks/useDashboard';
import { GraphTimeSelector, SitePowerGraph } from '../sites/site-detail-container/site-power';

const EnergyReading = () => {
  const theme = useTheme();
  const mainTimeSelectors = useMemo(() => getMainTimeSelectors(), []);
  const defaultTimeSelector = mainTimeSelectors[0];

  const timezone_id = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const selectedTab = useMemo(
    () => ({
      label: 'Solar Performance',
      value: 'SOLAR_PERFORMANCE',
      readingKeys: ['SOLAR_CONSUMPTION', 'EXPORT', 'BATTERY_CHARGE_SOLAR'].filter(Boolean),
      keyData: {
        SOLAR_CONSUMPTION: { dataKey: 'SOLAR_CONSUMPTION', name: 'Solar Consumption', fill: theme.primary_500 },
        EXPORT: { dataKey: 'EXPORT', name: 'Export', fill: theme.blue_100 },
        BATTERY_CHARGE_SOLAR: {
          dataKey: 'BATTERY_CHARGE_SOLAR',
          name: 'Battery Charge Solar',
          fill: theme.success_100,
        },
      },
    }),
    [theme],
  );

  const [timeSelector, setTimeSelector] = useState(defaultTimeSelector);

  const { start_date, end_date, defaultUnit: unit, start_date_iso, end_date_iso } = timeSelector;
  const { readingKeys, keyData, value } = selectedTab;

  const [energyReadings, setEnergyReadings] = useState({});

  const results = useGraphEnergyReadingData({
    energyTypes: selectedTab.readingKeys,
    params: { start_date: start_date_iso, end_date: end_date_iso, unit, timezone_id },
  });

  const isFetching = results.some(result => result.isFetching);

  useEffect(() => {
    const data = results.reduce((acc, result) => {
      const { data: resultData } = result || {};
      if (!resultData) {
        return acc;
      }
      const { key, data } = resultData || {};
      return {
        ...acc,
        [key]: data.map(value => ({ ...value, [key]: value?.value_kwh, type: key, value_kwh: null })),
      };
    }, {});
    setEnergyReadings(data);
  }, [results]);

  useEffect(() => {
    if (energyReadings) {
      const refetchFunc = results.map(result => result.refetch);
      refetchFunc.forEach(func => func());
    }
  }, [timeSelector, selectedTab]);

  return (
    <div className="flex flex-col rounded-xl gap-y-4 bg-white px-6 py-4 min-h-[350px] max-h-[420px] flex-1">
      <GraphTimeSelector timeSelector={timeSelector} setTimeSelector={setTimeSelector} />
      <SitePowerGraph
        selectedTabValue={value}
        readingKeys={readingKeys}
        keyData={keyData}
        energyReadings={energyReadings}
        isLoading={isFetching}
        timeSelector={timeSelector}
        setTimeSelector={setTimeSelector}
      />
    </div>
  );
};

export default EnergyReading;
