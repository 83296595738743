import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as RightIcon } from '../../assets/icons/arrow-right.svg';
import { ReactComponent as CompanyIcon } from '../../assets/icons/company-icon.svg';
import { ReactComponent as UserIcon } from '../../assets/icons/user-outline.svg';
import {
  capitalize,
  formatText,
  getContactValueByType,
  getFormattedDateV2,
  getFormattedNumber,
} from '../../helpers/utils';
import { cn } from '../../lib/utils';
import FlexBox from '../common/flex-box/flex';
import IconContainer from '../common/icon-container';
import Label from '../common/label';
import MenuV2 from '../common/menu/menu-v2';
import CustomTooltip from '../common/tooltip-new';

const AccountTootipDetail = ({ account, accountsLength, index }) => {
  const navigate = useNavigate();
  const lastIndex = accountsLength - 1;
  const { account_number, balance, service_address, next_payment_date, id: account_id } = account || {};
  const { formatted_address } = service_address || {};

  const onAccountDetailsClick = () => {
    navigate(`accounts/${account_id}`);
  };

  return (
    <div className={cn(lastIndex !== index && 'border-bottom')} onClick={onAccountDetailsClick}>
      <div className={cn('flex flex-col gap-y-1 p-4 hover:bg-neutral-50 rounded-md')}>
        <div className="flex items-center justify-between">
          <Label className="inter-500-text font-12">{account_number}</Label>
          <Label className="inter-500-text font-12">{getFormattedNumber(balance) || 0}</Label>
        </div>
        <Label className="inter-400-text font-10">{formatted_address}</Label>
        <Label className="inter-400-text font-10" hasPlaceholder={!next_payment_date}>
          <span className="natural-500-text">Next payment date:</span>{' '}
          {next_payment_date ? getFormattedDateV2({ dateInUnix: next_payment_date }) : 'Unknown'}
        </Label>
      </div>
    </div>
  );
};

const ContactAccounts = ({ accounts = [], contactId }) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const accountsLength = accounts.length || 0;

  return (
    <FlexBox className="flex items-center" tabIndex={1} onBlur={() => setIsTooltipOpen(false)}>
      <div
        data-tooltip-id={`contact-accounts-${contactId}`}
        className="flex items-center justify-center pl-3 pr-2 py-1 rounded-full bg-natural-100"
        onClick={() => setIsTooltipOpen(!isTooltipOpen)}>
        <Label className="inter-400-text mr-3" hasPlaceholder={!accountsLength}>
          {accountsLength}
        </Label>
        <IconContainer
          Icon={RightIcon}
          backgroundColor="transparent"
          iconWidth={12}
          iconHeight={12}
          iconColor="natural_600"
          iconClassName={cn('transition-all', isTooltipOpen && 'rotate-90')}
        />
      </div>
      <CustomTooltip
        tooltipId={`contact-accounts-${contactId}`}
        tooltipClassname="!px-0 !py-0 !w-[273px]"
        hidden={!accountsLength && !isTooltipOpen}
        isOpen={accountsLength && isTooltipOpen}
        wrapperClassName="!w-[273px]"
        wrapperClickable
        place="bottom"
        clickable
        content={
          <div className="inter-400-text">
            {accounts.map((account, index) => (
              <AccountTootipDetail key={account.id} account={account} index={index} accountsLength={3} />
            ))}
          </div>
        }
      />
    </FlexBox>
  );
};

const ContactListItem = ({ customer = {}, showBorderTop = false }) => {
  const { t } = useTranslation();
  const { id: customerId, name, phones, emails, addresses, accounts, contact_type } = customer || {};
  const isCompany = contact_type === 'COMPANY';
  const mobile = getContactValueByType(phones, 'contact_type', 'MOBILE', 'value') || '';
  const email = getContactValueByType(emails, 'is_primary', true, 'value') || '';
  const address = getContactValueByType(addresses, 'is_billing', true, 'formatted_address') || '';
  const iconType = isCompany ? CompanyIcon : UserIcon;

  return (
    <motion.div
      initial={{ opacity: 0.3, y: 10 }}
      animate={{ opacity: 1, y: 0, transition: { duration: 0.9 } }}
      key={customerId}
      className={cn(
        'px-6 py-4 grid grid-cols-contacts-list-grid gap-x-4 cursor hover:bg-neutral-50',
        showBorderTop && 'border-top',
      )}>
      <FlexBox className="one-line">
        <Label className="inter-400-text one-line" hasPlaceholder={!name}>
          {name || t('NO_TYPE', { type: formatText('name') })}
        </Label>
      </FlexBox>
      <FlexBox className="one-line">
        <IconContainer
          Icon={iconType}
          backgroundColor="transparent"
          iconContainerClassname="p-0 mr-2 rounded-2"
          iconColor="natural_600"
          iconWidth={16}
          iconHeight={16}
        />
        <Label className="inter-400-text one-line" hasPlaceholder={!contact_type}>
          {capitalize(formatText(contact_type)) || t('NO_TYPE', { type: formatText('type') })}
        </Label>
      </FlexBox>
      <FlexBox className="one-line">
        <Label className="inter-400-text one-line" hasPlaceholder={!email}>
          {email || t('NO_TYPE', { type: formatText('email') })}
        </Label>
      </FlexBox>
      <FlexBox className="one-line">
        <Label className="inter-400-text one-line" hasPlaceholder={!mobile}>
          {mobile || t('NO_TYPE', { type: formatText('phone') })}
        </Label>
      </FlexBox>
      <FlexBox>
        <Label className="inter-400-text" hasPlaceholder={!address}>
          {address || t('NO_TYPE', { type: formatText('billing address') })}
        </Label>
      </FlexBox>
      <ContactAccounts accounts={accounts} contactId={customerId} />
      <div className="flex items-center justify-center">
        <MenuV2
          portal={true}
          menuList={[
            {
              name: t('DETAILS'),
            },
          ]}
          iconClassname="rotate-90"
        />
      </div>
    </motion.div>
  );
};

export default ContactListItem;
