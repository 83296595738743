import { useQueries, useQuery } from '@tanstack/react-query';
import {
  getEnergySummaryGeneration,
  getEnergySummaryLastThreeHours,
  getEnergySummaryTopTenSites,
  getPowerStatus,
} from '../apis/dashboard';
import { getEnergyReadingDataByType } from '../apis/sites';

export const useGraphEnergyReadingData = ({ energyTypes, ...rest }) => {
  return useQueries({
    queries: energyTypes.map(energyType => ({
      enabled: !!energyType,
      select: data => {
        return { key: energyType, data };
      },
      queryKey: ['energyReadingData', { reading_type: energyType }],
      queryFn: () => getEnergyReadingDataByType({ reading_type: energyType, ...rest }),
    })),
    combine: results => results,
  });
};

export const useEnergySummaryGeneration = ({ ...rest }) => {
  return useQuery({
    queryKey: ['energySummaryGeneration'],
    queryFn: () => getEnergySummaryGeneration({ ...rest }),
  });
};

export const useEnergySummaryLastThreeHours = ({ ...rest }) => {
  return useQuery({
    queryKey: ['energySummaryLastThreeHours'],
    queryFn: () => getEnergySummaryLastThreeHours({ ...rest }),
  });
};

export const useEnergySummaryTopTenSites = ({ ...rest }) => {
  return useQuery({
    queryKey: ['energySummaryTopTenSites'],
    queryFn: () => getEnergySummaryTopTenSites({ ...rest }),
  });
};

export const usePowerStatus = ({ ...rest }) => {
  return useQuery({
    queryKey: ['powerStatus'],
    queryFn: () => getPowerStatus({ ...rest }),
  });
};
