import classNames from 'classnames';
import { nanoid } from 'nanoid';
import React, { useContext, useState } from 'react';
import { components } from 'react-select';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import { ReactComponent as CrossIcon } from '../../assets/icons/toast-close.svg';
import { Avatar } from '../../components/common/avatar-group/index.js';
import Button from '../../components/common/button/button.js';
import InputElement from '../../components/common/input/index.js';
import SearchableDropdown from '../../components/common/searchable-dropdown/index.js';
import { OrganisationContext } from '../../context/organisationContext.js';
import { initModal } from '../../helpers/utils.js';
import { useError } from '../../hooks/useError.js';
import { createAutomation } from '../../store/features/automationsSlice.js';
import { addToast } from '../../store/features/toastSlice.js';
import { getUsers } from '../../store/features/userManagementSlice.js';

const CustomOption = ({ innerProps, data, isSelected }) => {
  return (
    <div
      className={classNames(
        'flex items-center w-full px-4 py-3 cursor border-bottom option-wrapper',
        isSelected && 'selected',
      )}
      {...innerProps}>
      <Avatar avatar={data} variant="small" backgroundColorIndex={data?.optionIndex ?? 1} />
      <span
        className={classNames(
          'flex-1 inter-400-text natural-900-text option-text ml-2',
          isSelected && 'inter-500-text',
        )}>
        {data.label}
      </span>
    </div>
  );
};

const CustomSingleValue = ({ data, ...rest }) => {
  return (
    <components.SingleValue {...rest} className="flex items-center">
      <Avatar avatar={data} variant="medium" backgroundColorIndex={data?.optionIndex ?? 1} />
      <span className="flex-1 inter-400-text natural-900-text option-text ml-2">{data.label}</span>
    </components.SingleValue>
  );
};

const AddAutomation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { showErrorToast } = useError();

  const { t } = useTranslation();

  const { setModal } = useContext(OrganisationContext);

  const [automation, setAutomation] = useState({});
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const [searchedOwner, setSearchedOwner] = useState('');

  const { name, description, owner } = automation;

  const checkAutomationErrors = () => {
    if (!name?.trim() || !description?.trim() || !owner?.id) {
      setError({
        name: !name?.trim(),
        owner: !owner?.id,
        description: !description?.trim(),
      });
      dispatch(addToast({ error: true, text: t('AUTOMATION_NAME_DESCRIPTION_ERROR'), id: nanoid() }));
      return true;
    }
    return false;
  };

  const onDone = async () => {
    if (loading) {
      return;
    }
    if (checkAutomationErrors()) {
      return;
    }
    setLoading(true);
    const request = {
      name: name,
      description: description,
      owner: { id: owner.id },
    };
    dispatch(createAutomation({ request }))
      .then(({ data }) => {
        setLoading(false);
        setModal(initModal);
        dispatch(
          addToast({
            error: false,
            title: t('AUTOMATION_ADDED'),
            text: t('SUCCESSFULLY_ADDED_AUTOMATION'),
            id: nanoid(),
          }),
        );

        navigate(`/platforms/automations/${data?.id}/configuration`);
      })
      .catch(error => {
        showErrorToast({ error, default_message: t('ERROR_ADDED_AUTOMATION') });
        setLoading(false);
      });
  };

  const fetchChildOpt = async (
    search,
    _prevOptions,
    { page, merge, fetchFunction, hasMore, pageable, params = {}, payload = {} },
  ) => {
    if (!fetchFunction) {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: 0,
          merge: merge,
          fetchFunction: fetchFunction,
          hasMore: hasMore,
        },
      };
    }
    try {
      const optionData = await dispatch(
        fetchFunction({
          forFetchOnly: true,
          params: {
            page: page,
            search: search,
            ...params,
          },
          ...payload,
        }),
      );
      let optionContent = [];
      let last = true;
      if (pageable) {
        const { content, ...restResponse } = optionData || {};
        optionContent = optionData ? content : [];
        last = restResponse.last;
      } else {
        optionContent = optionData || [];
      }
      const changedOptions = optionContent.map((option, index) => ({
        ...option,
        label: option.name,
        value: option.id,
        optionIndex: index,
      }));

      return {
        options: changedOptions,
        hasMore: !last,
        additional: {
          page: page + 1,
          merge: merge,
          fetchFunction: fetchFunction,
          hasMore: !last,
          pageable,
        },
      };
    } catch (error) {
      return {
        options: [],
        hasMore: hasMore,
        additional: {
          page: page,
          merge: merge,
          fetchFunction: fetchFunction,
          hasMore: hasMore,
        },
      };
    }
  };

  return (
    <CSSTransition appear classNames="popup-fade" in timeout={300}>
      <AddAutomationWrapper className="flex-column px-10 py-9">
        <div className="flex justify-between">
          <p className="inter-600-text natural-900-text font-20">{t('ADD_AUTOMATION')}</p>
          <CrossIcon width={24} height={24} className="cursor" onClick={() => setModal(initModal)} />
        </div>
        <div className="flex-column row-gap-6 mt-8">
          <div className="w-full flex-column">
            <InputElement
              name={t('NAME')}
              onChange={value => {
                setAutomation({ ...automation, name: value });
                setError({ ...error, name: false });
              }}
              error={error.name}
              value={automation.name || ''}
              placeholder={t('ENTER_AUTOMATION_NAME')}
            />
          </div>
          <div className="w-full flex-column">
            <InputElement
              name={t('DESCRIPTION')}
              onChange={value => {
                setAutomation({ ...automation, description: value });
                setError({ ...error, description: false });
              }}
              error={error.description}
              value={automation.description || ''}
              placeholder={t('ENTER_AUTOMATION_DESCRIPTION')}
              type="textarea"
            />
          </div>
          <div className="w-full flex-column">
            <SearchableDropdown
              isClearable
              inputValue={searchedOwner}
              onInputChange={setSearchedOwner}
              loadOptions={fetchChildOpt}
              placeholder={t('SELECT_OWNER')}
              name={t('OWNER')}
              value={automation?.owner}
              onChange={option => {
                setAutomation({ ...automation, owner: option });
                setError({ ...error, owner: false });
              }}
              defaultAdditional={{
                page: 0,
                fetchFunction: getUsers,
                pageable: true,
              }}
              error={error.owner && !automation?.owner?.id}
              customComponent={{
                Option: CustomOption,
                SingleValue: CustomSingleValue,
              }}
            />
          </div>
        </div>
        <div className="flex col-gap-6 justify-center mt-12 w-full">
          <Button
            className={classNames('primary-white flex-1', loading && 'disabled')}
            label={t('CANCEL')}
            disabled={loading}
            onClick={() => setModal(initModal)}
            size="large"
            borderRadius="100px"
          />
          <Button
            className={classNames('primary flex-1', loading && 'disabled')}
            label={t('ADD')}
            disabled={loading}
            onClick={onDone}
            size="large"
            borderRadius="100px"
          />
        </div>
      </AddAutomationWrapper>
    </CSSTransition>
  );
};

const AddAutomationWrapper = styled.div`
  width: 450px;

  .options-select-container {
    width: 360px;

    .select__placeholder {
      color: ${({ theme }) => theme.greyText};
      opacity: 0.8;
      font-family: Roboto Regular;
    }

    .select__value-container {
      padding-left: 15px;
    }

    .select__control {
      border-radius: 100px;
      border: 1px solid ${({ theme }) => theme.lightGrey};
    }

    .select__control--is-focused {
      border: 1px solid ${({ theme }) => theme.lightGreyBackground};
      box-shadow: unset;
    }

    .select__multi-value {
      background: rgba(210, 229, 237, 0.3);
      border-radius: 4px;
    }
    .select__multi-value__label {
      color: #053149;
      font-size: 16px;
      font-family: Roboto Regular;
    }

    .select__clear-indicator,
    .select__indicator-separator {
      display: none;
    }
  }

  .description-input {
    min-height: 100px;
    resize: none;
    outline: none;
    max-height: 200px;
  }

  .provider-selector {
    .organisations-border {
      height: 48px;
      border-color: ${({ theme }) => theme.lightGrey};
    }
    .mc-options {
      top: 48px;
    }
  }

  .sub-provider {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    column-gap: 10px;
    row-gap: 10px;
  }

  .sub-provider-item {
    background: ${({ theme }) => theme.lightGreyBackground};
    border: 1px solid ${({ theme }) => theme.lightGreyBackground};
    border-radius: 100px;
  }

  .selected-sub-provider {
    border: 1px solid ${({ theme }) => theme.primary_500};
    background: #ffffff;
  }

  .error-border {
    border-color: red;

    .organisations-border {
      border-color: red !important;
    }
  }
`;

export default AddAutomation;
