import api from '../store/services/index';

const fetchAPI = async ({ url, method, setDataFunc, ...rest }) => {
  try {
    const { data } = await api({ url, method, ...rest });
    setDataFunc && setDataFunc(data);
    return data;
  } catch (error) {
    setDataFunc && setDataFunc(null);
    return Promise.reject(error);
  }
};

export const getBills = async ({ ...rest }) => {
  return fetchAPI({ url: '/energy_bills', method: 'GET', ...rest });
};

export const getActionIntegrations = async ({ action_type, ...rest }) => {
  return fetchAPI({ url: `/actions/${action_type}/integrations`, method: 'GET', ...rest });
};
