import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { getFormattedNumber, getFormattedNumberStyle } from '../../helpers/utils';
import { useEnergySummaryGeneration } from '../../hooks/useDashboard';
import Label from '../common/label';

const StatUI = ({ title, data, loading }) => {
  const { generation, revenue } = data || {};
  const formattedGeneration = getFormattedNumberStyle(generation, 'decimal', 0, 0);
  const formattedRevenue = getFormattedNumber(revenue);

  return (
    <div className="flex flex-col px-6 py-4 bg-white rounded-xl">
      <Label className="inter-500-text natural-400-text font-16">{title}</Label>
      {loading ? (
        <Skeleton height={30} />
      ) : (
        <div className="flex items-center">
          <Label className="inter-600-text font-20">{formattedGeneration}</Label>
          <Label className="inter-600-text font-16 ml-1">kWh</Label>
          <div className="w-2 h-2 bg-natural-500 rounded-full mx-3"></div>
          <Label className="inter-600-text natural-500-text font-20">{formattedRevenue}</Label>
        </div>
      )}
    </div>
  );
};

const GenerationStats = () => {
  const { data: energySummaryGeneration, isFetching } = useEnergySummaryGeneration({});

  const { day, month, week, year } = energySummaryGeneration || {};

  return (
    <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
      <StatUI title="Generation - Year to date" data={year} loading={isFetching} />
      <StatUI title="Generation - Month to date" data={month} loading={isFetching} />
      <StatUI title="Generation - Week to date" data={week} loading={isFetching} />
      <StatUI title="Generation - Day to date" data={day} loading={isFetching} />
    </div>
  );
};

export default GenerationStats;
