import { nanoid } from 'nanoid';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ReactComponent as PlusIcon } from '../../../../assets/icons/add-icon.svg';
import { ReactComponent as MouseClickIcon } from '../../../../assets/icons/mouse-click.svg';
import { OrganisationContext } from '../../../../context/organisationContext';
import { ConfigurationWrapper } from '../../../../styles/components/automationDetails/automation-details.styled';
import Button from '../../../common/button/button';
import IconContainer from '../../../common/icon-container';
import AutomationAction from './automation-action';
import AutomationTrigger from './automation-trigger';

const Configuration = () => {
  const { t } = useTranslation();

  const { setModal } = useContext(OrganisationContext);

  const { automationDetails } = useSelector(state => state.automations);
  const { event_type, actions } = automationDetails || {};

  const [automationActions, setAutomationActions] = useState(actions || []);

  const onAddAction = () => {
    setModal({
      type: 'add-action',
      content: {
        automationDetails,
        event_type,
        isEdit: false,
        onSuccess: data => {
          setAutomationActions([...automationActions, { ...data, isNew: true, action_id: nanoid() }]);
        },
      },
    });
  };

  const onAddOrUpdateAction = (actionData, isAdded = false) => {
    const newActions = [...automationActions].map(action => {
      const { action_id, id, ...rest } = actionData || {};
      if (isAdded && action.action_id === action_id) {
        return { id, ...rest };
      }
      if (!isAdded && action.id === id) {
        return { id, ...rest };
      }
      return action;
    });
    setAutomationActions(newActions);
  };

  const onCancelOrRemoveAction = (actionData, isDelete = false) => {
    const newActions = [...automationActions].filter(action => {
      const { action_id, id, ...rest } = actionData || {};
      if (isDelete && action.id === id) {
        return false;
      }
      if (!isDelete && action.action_id === action_id) {
        return false;
      }
      return true;
    });
    setAutomationActions(newActions);
  };

  const onResetActionData = actionData => {
    const newActions = [...automationActions].map(action => (action.id === actionData.id ? { ...actionData } : action));
    setAutomationActions(newActions);
  };

  useEffect(() => {
    setAutomationActions(actions || []);
  }, [actions]);

  return (
    <ConfigurationWrapper
      className="flex-column items-start justify-start w-full h-full overflow-scroll mt-6"
      style={{ height: '80vh' }}>
      <div className="flex-column flex-1 items-start justify-start w-full px-6 pb-6 overflow-scroll relative min-w-fit-content">
        <AutomationTrigger />
        {event_type && (
          <Fragment>
            {automationActions.length > 0 ? (
              <div className="flex-column flex-1 w-full">
                {automationActions?.map((action, index) => (
                  <AutomationAction
                    key={action.id}
                    action={action}
                    index={index + 1}
                    onAddOrUpdateAction={onAddOrUpdateAction}
                    onCancelOrRemoveAction={onCancelOrRemoveAction}
                    onResetActionData={onResetActionData}
                  />
                ))}
                <Button
                  size="average"
                  label={t('ADD_NEW_ACTION')}
                  afterIcon={<PlusIcon className="primary-500-text" />}
                  className="primary-blue flex mt-6"
                  onClick={onAddAction}
                />
              </div>
            ) : (
              <div className="flex-column w-full h-auto px-6 py-5 border radius-1_5 row-gap-6 mt-8">
                <div className="flex col-gap-3 border-bottom pb-4">
                  <IconContainer
                    iconContainerClassname="border radius-1_5"
                    backgroundColor="white"
                    Icon={MouseClickIcon}
                    iconColor="primary_500"
                    iconWidth={35}
                    iconHeight={35}
                  />
                  <div className="flex-column">
                    <span className="inter-600-text natural-700-text font-18">{`${t('ACTION')} 1`}</span>
                    <span className="inter-400-text natural-500-text">{t('AUTOMATION_TRIGGER_CRITERIA')}</span>
                  </div>
                </div>
                <Button
                  label={t('ADD_ACTION')}
                  className="secondary"
                  size="large"
                  afterIcon={<PlusIcon className="primary-500-text" />}
                  onClick={onAddAction}
                />
              </div>
            )}
          </Fragment>
        )}
      </div>
    </ConfigurationWrapper>
  );
};

export default Configuration;
