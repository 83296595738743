import React from 'react';
import styled from 'styled-components';
import { ReactComponent as ArrowRoundLightningIcon } from '../../../assets/icons/arrow-round-lightning.svg';
import { ReactComponent as PanelArrowDownIcon } from '../../../assets/icons/panel-with-arrow-down.svg';
import { ReactComponent as PanelArrowUpIcon } from '../../../assets/icons/panel-with-arrow-up.svg';
import { ReactComponent as SolarHomeIcon } from '../../../assets/icons/solar-home.svg';
import { getFormattedNumberStyle, uppercase } from '../../../helpers/utils';
import { useAccountsStore } from '../../../store/accounts-store';
import { useSitesStore } from '../../../store/sites-store';
import Card from '../../common/card';
import IconContainer from '../../common/icon-container';
import Label from '../../common/label';

export const StatInfo = ({ loading, value, valueFormat, icon, iconColor, label, children }) => {
  return (
    <Card className="flex-column px-6 py-4">
      <div className="flex items-center">
        <IconContainer
          Icon={icon}
          iconHeight={40}
          iconWidth={40}
          iconColor={iconColor}
          backgroundColor="transparent"
          iconContainerClassname="p-0 mr-4"
        />
        <div className="flex-column row-gap-1">
          <span className="inter-600-text natural-500-text font-12 letter-spacing-1">{uppercase(label)}</span>
          <Label loading={loading} className="inter-600-text font-20">
            {value}
            {valueFormat ? <span className="font-16 ml-1">{valueFormat}</span> : null}
          </Label>
        </div>
      </div>
      {children}
    </Card>
  );
};

const SiteStats = ({ loading }) => {
  const { siteDetails } = useSitesStore();
  const { accountDetails } = useAccountsStore();

  const { system_size = 0, system_stats } = siteDetails || {};
  const { total_consumed = 0, total_generated = 0, total_exported = 0, total_charged = 0 } = system_stats || {};

  const total_charged_consumed = parseFloat(total_consumed) + parseFloat(total_charged);

  const { balance = 0, next_payment_date = null } = accountDetails || {};

  return (
    <SiteStatsWrapper className="flex-column row-gap-4">
      <StatInfo
        value={getFormattedNumberStyle(system_size, 'decimal', 0, 0)}
        icon={SolarHomeIcon}
        iconColor="blue_500"
        label="system size"
        valueFormat="kW"
        loading={loading}
      />
      <StatInfo
        value={getFormattedNumberStyle(total_generated, 'decimal', 2, 2)}
        icon={ArrowRoundLightningIcon}
        iconColor="orange_500"
        label="total generated"
        valueFormat="kWh"
        loading={loading}
      />
      <StatInfo
        value={getFormattedNumberStyle(total_charged_consumed, 'decimal', 2, 2)}
        icon={PanelArrowDownIcon}
        iconColor="success_500"
        label="total consumed"
        valueFormat="kWh"
        loading={loading}
      />
      <StatInfo
        value={getFormattedNumberStyle(total_exported, 'decimal', 2, 2)}
        icon={PanelArrowUpIcon}
        iconColor="pink_500"
        label="total exported"
        valueFormat="kWh"
        loading={loading}
      />
      {/* <StatInfo
        value={getFormattedNumber(balance, 'GBP', 0, 0)}
        icon={WalletMoneyIcon}
        iconColor="orange_500"
        loading={loading}
        label="account balance">
        <div className="flex items-center justify-between py-1 px-4 radius-1 bg-natural-100 mt-4">
          <label className="inter-400-text natural-700-text font-12 flex-1">Next payment:</label>
          <Label loading={loading} className={cn('inter-500-text font-12', !next_payment_date && 'natural-400-text')}>
            {next_payment_date ? getFormattedDateV2({ dateInUnix: next_payment_date }) : 'No date available'}
          </Label>
        </div>
      </StatInfo>
      <StatInfo
        value={getFormattedNumber(0, 'GBP', 0, 0)}
        icon={HandCoinShareIcon}
        loading={loading}
        iconColor="error_500"
        label="lifetime spend"
      /> */}
    </SiteStatsWrapper>
  );
};

const SiteStatsWrapper = styled.div`
  min-width: 320px;
  max-width: 24%;
`;

export default SiteStats;
