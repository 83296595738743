import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';
import useDebounce from '../../helpers/useDebounceHook';
import { useAccountsStore } from '../../store/accounts-store';
import AccountDetailContainer from '../accounts/account-detail-container';
import BreadcrumbWrapper from '../common/breadcrumb-wrapper';
import PageHeader from '../page-header';
import CustomersList from './contacts-list';

const Contacts = () => {
  const { t } = useTranslation();
  const { accountDetails } = useAccountsStore();
  const { account_number } = accountDetails || {};
  const [searchText, setSearchText] = useState('');
  const debouncedSearch = useDebounce(searchText, 500);

  return (
    <Routes>
      <Route
        element={
          <div className="flex-column flex-1 pxy-5 bg-natural-150 overflow-auto">
            <BreadcrumbWrapper breadcrumbs={[{ text: t('CUSTOMERS'), path: `/customers` }, { text: account_number }]} />
            <AccountDetailContainer />
          </div>
        }
        path="/accounts/:account_id"
      />
      <Route
        index
        element={
          <div className="flex-column flex-1 max-h-[100vh] overflow-hidden">
            <PageHeader
              headerTitle={t('CUSTOMERS')}
              showAddNewBtn={false}
              // showFilter
              showSearch
              // showSort
              onSortClick={() => {}}
              searchText={searchText}
              onSearchChange={setSearchText}
            />
            <CustomersList debouncedSearch={debouncedSearch} />
          </div>
        }
      />
    </Routes>
  );
};

export default Contacts;
