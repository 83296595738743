import { keepPreviousData, useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getActionIntegrations, getBills } from '../apis/bills';
import { updateInvoiceIntegration } from '../apis/invoices';
import { useError } from './useError';
import { useSuccess } from './useSuccess';

export const useBills = ({ params, hasNextPage, enabled = true, ...rest }) => {
  return useInfiniteQuery({
    initialPageParam: 0,
    enabled: enabled,
    queryKey: ['bills', { ...params }],
    select: data => {
      return data?.pages?.reduce((acc, page) => {
        return [...acc, ...page.content];
      }, []);
    },
    placeholderData: keepPreviousData,
    queryFn: ({ pageParam = 0 }) => getBills({ params: { page: pageParam, ...params }, ...rest }),
    getNextPageParam: lastPage => {
      const { last, number } = lastPage;
      return last ? undefined : number + 1;
    },
  });
};

export const useActionIntegrations = ({ action_type, ...rest }) => {
  return useQuery({
    queryKey: ['action-integrations', { action_type }],
    queryFn: () => getActionIntegrations({ action_type, ...rest }),
  });
};

const tempData = {
  id: 'string',
  created_on: '2024-09-28T10:04:09.920Z',
  created_by: {
    id: 'string',
    name: 'string',
  },
  code: 'string',
  description: 'string',
  status: 'DRAFT',
  integration: {
    id: '68c0fbf8-94ca-482d-a6c0-bb1ef6a73b13',
    name: 'XERO Orbit',
    connector: {
      icon: {
        active: 'https://api.dev.zervio.com/icons/d10b474c-ed38-11ee-9378-42010a9a0015/active',
        inactive: 'https://api.dev.zervio.com/icons/d10b474c-ed38-11ee-9378-42010a9a0015/inactive',
      },
    },
  },
  total_amount: 0,
  total_tax: 0,
  total_net: 0,
  due_date: '2024-09-28T10:04:09.920Z',
  quote: {
    id: 'string',
    code: 'string',
    description: 'string',
    status: 'ESTIMATE',
    salesperson: {
      id: 'string',
      name: 'string',
    },
  },
  payment_terms_template_item: {
    id: 'string',
    name: 'string',
    description: 'string',
    percentage: 0,
    due_count: 0,
    is_required: true,
    priority: 0,
  },
  line_items: [
    {
      id: 'string',
      sku: 'string',
      product: {
        id: 'string',
      },
      name: 'string',
      description: 'string',
      external_reference: 'string',
      line_no: 0,
      product_type: 'PHYSICAL',
      qty: 0,
      unit: 'COUNT',
      unit_price: 0,
      discount_rate: 0,
      total_net: 0,
      total_tax: 0,
      total_amount: 0,
      supplier: {
        id: 'string',
        name: 'string',
      },
      taxation_scheme: {
        id: 'string',
        name: 'string',
        rate: 0,
      },
      accounting_external_reference: 'string',
    },
  ],
  account: {
    id: 'string',
    account_number: 'string',
    billing_email: 'string',
    contact: {
      id: 'string',
      name: 'string',
      contact_type: 'COMPANY',
      phones: [
        {
          id: 'string',
          contact_type: 'LANDLINE',
          value: 'string',
          is_primary: true,
          phone_type: 'WORK',
          country_code: 'string',
        },
      ],
    },
  },
  issuedDate: '2024-09-28T10:04:09.920Z',
  paid_date: '2024-09-28T10:04:09.920Z',
  payment_count: 0,
};

export const useUpdateInvoiceIntegration = ({ invoice_id, ...rest }) => {
  const queryClient = useQueryClient();
  const successToast = useSuccess();
  const errorToast = useError();

  return useMutation({
    mutationFn: ({ integration_id }) => updateInvoiceIntegration({ invoice_id, integration_id, ...rest }),
    onSuccess: data => {
      const { integration } = data;
      const { connector } = integration || {};
      queryClient.setQueriesData({ queryKey: ['bills'] }, prev => {
        return {
          ...prev,
          pages: prev.pages.map(page => {
            return {
              ...page,
              content: page.content.map(bill => {
                if (bill?.invoice?.id === invoice_id) {
                  return { ...bill, integration: { ...integration, icon: connector?.icon || {} } };
                }
                return bill;
              }),
            };
          }),
        };
      });
      queryClient.invalidateQueries({ queryKey: ['bills'] });
      successToast.showToastFunc({ default_message: 'Invoice integration updated successfully' });
    },
    onError: error => {
      errorToast.showErrorToast({ error, default_message: 'Failed to update invoice integration' });
    },
  });
};
