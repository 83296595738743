import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import uuid from 'react-uuid';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import { OrganisationContext } from '../../context/organisationContext';
import { isDataChanged } from '../../helpers/utils';
import { useError } from '../../hooks/useError';
import {
  setIntegrationDetails,
  updateIntegration,
  updateIntegrationState,
} from '../../store/features/integrationsSlice';
import { addToast } from '../../store/features/toastSlice';
import { IntegrationsDetailLeftWrapper } from '../../styles/components/integrations-detail/integrations-detail.styled';
import Menu from '../common/menu';
import Switch from '../common/switch';
import UpdateAction from '../common/update-action';

const IntegrationDetailsLeft = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { showErrorToast } = useError();

  const { setModal } = useContext(OrganisationContext);
  const { integrationDetails } = useSelector(state => state.integrations);

  const [updateIntegrationDetail, setUpdateIntegrationDetail] = useState({ type: '', value: '' });

  const changeIntegrationDetailsStatus = is_enabled => {
    dispatch(setIntegrationDetails({ ...integrationDetails, is_enabled: is_enabled }));
  };

  const onChangeStatus = async () => {
    const { is_enabled } = integrationDetails;
    if (is_enabled) {
      setModal({
        type: 'integration-action',
        content: {
          integration: integrationDetails,
          top: true,
          isDisable: true,
          onSuccess: () => changeIntegrationDetailsStatus(!is_enabled),
        },
      });
    } else {
      const request = {
        enabled: !is_enabled,
      };
      changeIntegrationDetailsStatus(!is_enabled);
      dispatch(updateIntegrationState({ id: integrationDetails?.id, request, updateList: true })).catch(error => {
        const errorData = error?.response?.data;
        changeIntegrationDetailsStatus(is_enabled);
        const text =
          errorData?.error_code === 'BAD_REQUEST' ? t('REQUIRED_PARAMETERS_INTIGRATION') : t('ERROR_UPDATE_STATUS');
        showErrorToast({ error, default_message: text });
      });
    }
  };

  const onCancelUpdate = () => {
    setUpdateIntegrationDetail({ type: '', value: '' });
  };

  const onUpdate = () => {
    if (!updateIntegrationDetail.value.trim()) {
      dispatch(addToast({ error: true, text: t('REQUIRED_FIELD'), id: uuid() }));
      return;
    }
    const integrationDetailData = { ...integrationDetails };
    const updatedDetails = {
      ...integrationDetailData,
      [updateIntegrationDetail.type]: updateIntegrationDetail.value.trim(),
    };
    dispatch(setIntegrationDetails(updatedDetails));
    dispatch(updateIntegration({ id: updatedDetails.id, request: updatedDetails }))
      .then(() => {
        dispatch(addToast({ error: false, text: t('INTEGRATION_UPDATED_SUCCESSFULLY'), id: uuid() }));
      })
      .catch(error => {
        dispatch(setIntegrationDetails(integrationDetailData));
        showErrorToast({ error, default_message: t('ERROR_WHILE_UPDATING_DETAILS') });
      });
    setUpdateIntegrationDetail({ type: '', value: '' });
  };

  const onDelete = () => {
    setModal({
      type: 'integration-action',
      content: {
        integration: integrationDetails,
        top: true,
        isDelete: true,
        onSuccess: () => navigate('/platforms/integrations'),
      },
    });
  };

  return (
    <IntegrationsDetailLeftWrapper className="border flex-column px-3 py-5">
      <div className="flex items-center border-bottom pb-3 mx-3">
        <img alt="log" src={integrationDetails?.connector?.icon?.active} width={24} height={24} />
        <label className="flex-1 font-18 inter-600-text natural-900-text ml-2">
          {integrationDetails?.connector?.name}
        </label>
        <Menu menuList={[{ name: t('DELETE'), onClick: () => onDelete(), permission: 'INTEGRATIONS_MANAGE' }]} />
      </div>
      <div className="flex-column flex-1 overflow-scroll pt-4 row-gap-1">
        <div className="flex-column">
          {updateIntegrationDetail.type === 'name' ? (
            <div className="update-detail-contaner mx-1 relative">
              <div className="flex-column row-gap-1 w-full">
                <span className="inter-500-text natural-900-text font-14 mb-1">{t('NAME')}</span>
                <input
                  className="input w-full"
                  value={updateIntegrationDetail.value}
                  onChange={({ target }) =>
                    setUpdateIntegrationDetail({ ...updateIntegrationDetail, value: target.value })
                  }
                  placeholder="Name"
                />
              </div>
              <UpdateAction
                size="medium"
                onCancel={onCancelUpdate}
                onUpdate={() =>
                  isDataChanged(
                    'INPUT',
                    updateIntegrationDetail.value?.trim(),
                    integrationDetails?.name?.trim(),
                    () => onUpdate(),
                    () => onCancelUpdate(),
                  )
                }
                width="128px"
                height="40px"
                className="w-fit-content"
                btnClassname="specified-width"
              />
            </div>
          ) : (
            <div
              className="flex-column py-2 px-2 mx-1 cursor hover-edit relative"
              onClick={() => setUpdateIntegrationDetail({ type: 'name', value: integrationDetails?.name })}>
              <span className="inter-400-text natural-400-text font-12 mb-1">{t('NAME')}</span>
              <div className="flex">
                <span className="inter-400-text natural-900-text">{integrationDetails?.name}</span>
              </div>
              <EditIcon width={16} height={16} className="edit-icon" color="#2496FF" />
            </div>
          )}
        </div>
        <div className="flex-column">
          {updateIntegrationDetail.type === 'description' ? (
            <div className="update-detail-contaner mx-1 relative">
              <div className="flex-column row-gap-1 w-full">
                <span className="inter-500-text natural-900-text font-14 mb-1">{t('DESCRIPTION')}</span>
                <textarea
                  className="input textarea w-full"
                  value={updateIntegrationDetail.value}
                  onChange={({ target }) =>
                    setUpdateIntegrationDetail({ ...updateIntegrationDetail, value: target.value })
                  }
                  placeholder={t('NAME')}
                  rows={4}
                />
              </div>
              <UpdateAction
                size="medium"
                onCancel={onCancelUpdate}
                onUpdate={() =>
                  isDataChanged(
                    'INPUT',
                    updateIntegrationDetail.value?.trim(),
                    integrationDetails?.description?.trim(),
                    () => onUpdate(),
                    () => onCancelUpdate(),
                  )
                }
                btnClassname="specified-width"
                width="128px"
                height="40px"
                className="w-fit-content"
              />
            </div>
          ) : (
            <div
              className="flex-column py-2 px-2 mx-1 cursor hover-edit relative"
              onClick={() =>
                setUpdateIntegrationDetail({ type: 'description', value: integrationDetails?.description })
              }>
              <span className="inter-400-text natural-400-text font-12 mb-1">{t('DESCRIPTION')}</span>
              <div className="flex w-75">
                <p className="inter-400-text natural-900-text">{integrationDetails?.description}</p>
              </div>
              <EditIcon width={16} height={16} className="edit-icon" color="#2496FF" />
            </div>
          )}
        </div>
        <div className="px-3 mt-5">
          <div className="flex items-center justify-between border radius-2 pxy-3">
            <span className={`inter-400-text natural-900-text flex-1 flex items-center`}>
              {integrationDetails?.is_enabled ? t('ENABLED') : t('DISABLED')}
            </span>
            <Switch
              onClick={() => onChangeStatus()}
              enabled={integrationDetails?.is_enabled}
              disabled={false}
              permission="INTEGRATIONS_MANAGE"
            />
          </div>
        </div>
      </div>
    </IntegrationsDetailLeftWrapper>
  );
};

export default IntegrationDetailsLeft;
