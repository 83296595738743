import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { getFormattedNumberStyle } from '../../helpers/utils';
import { useEnergySummaryTopTenSites } from '../../hooks/useDashboard';
import Label from '../common/label';

const TopSiteUI = ({ site }) => {
  const { formatted_address, solar_generation, export: export_data, ev_charging } = site || {};

  const formattedSolarGeneration = getFormattedNumberStyle(solar_generation, 'decimal', 1, 1);
  const formattedExport = getFormattedNumberStyle(export_data, 'decimal', 1, 1);
  const formattedEvCharging = getFormattedNumberStyle(ev_charging, 'decimal', 1, 1);

  const totalEnergy = solar_generation + export_data + ev_charging;
  const solarGenerationPercentage = ((solar_generation / totalEnergy) * 100).toFixed(1);
  const exportPercentage = ((export_data / totalEnergy) * 100).toFixed(1);
  const evChargingPercentage = ((ev_charging / totalEnergy) * 100).toFixed(1);

  return (
    <div key={formatted_address} className="flex flex-col items-center justify-between border rounded-lg px-3 py-3">
      <Label className="inter-500-text">{formatted_address}</Label>
      <div className="flex items-center w-full mt-3 gap-x-[2px]">
        <div className="h-1 bg-pink-400 rounded-sm mb-1" style={{ width: `${solarGenerationPercentage}%` }} />
        <div className="h-1 bg-yellow-300 rounded-sm mb-1" style={{ width: `${exportPercentage}%` }} />
        <div className="h-1 bg-green-400 rounded-sm mb-1" style={{ width: `${evChargingPercentage}%` }} />
      </div>
      <div className="flex items-center gap-x-1 flex-1 w-full">
        <div className="flex flex-col items-start flex-1">
          <Label className="inter-500-text font-10">
            <div className="w-2 h-2 bg-pink-400 rounded-full inline-block mr-1" />
            {formattedSolarGeneration}kwh
          </Label>
          <Label className="inter-400-text font-10 ml-3">Energy</Label>
        </div>
        <div className="flex flex-col items-start flex-1">
          <Label className="inter-500-text font-10">
            <div className="w-2 h-2 bg-yellow-300 rounded-full inline-block mr-1" />
            {formattedExport}kwh
          </Label>
          <Label className="inter-400-text font-10 ml-3">Export</Label>
        </div>
        <div className="flex flex-col items-start flex-1">
          <Label className="inter-500-text font-10">
            <div className="w-2 h-2 bg-green-400 rounded-full inline-block mr-1" />
            {formattedEvCharging}kwh
          </Label>
          <Label className="inter-400-text font-10 ml-3">EV Charging</Label>
        </div>
      </div>
    </div>
  );
};

const TopSites = () => {
  const { data: topSites, isFetching } = useEnergySummaryTopTenSites({});

  const isDataEmpty = !topSites?.length;

  return (
    <div className="flex flex-col px-6 py-4 bg-white rounded-xl">
      <div className="inter-600-text font-18 mb-4">Top 10 Sites</div>
      {isFetching ? (
        <Skeleton count={2} height={120} />
      ) : isDataEmpty ? (
        <label className="inter-400-text natural-500-text font-16">No data available</label>
      ) : (
        <div className="flex flex-col gap-y-3">
          {topSites?.map(site => (
            <TopSiteUI key={site.formatted_address} site={site} />
          ))}
        </div>
      )}
    </div>
  );
};

export default TopSites;
