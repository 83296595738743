import api from '../store/services/index';

const fetchAPI = async ({ url, method, setDataFunc, ...rest }) => {
  try {
    const { data } = await api({ url, method, ...rest });
    setDataFunc && setDataFunc(data);
    return data;
  } catch (error) {
    setDataFunc && setDataFunc(null);
    return Promise.reject(error);
  }
};

export const getEnergySummaryGeneration = async ({ ...rest }) => {
  return fetchAPI({ url: `/energy_summary/generation`, method: 'GET', ...rest });
};

export const getEnergySummaryLastThreeHours = async ({ ...rest }) => {
  return fetchAPI({ url: `/energy_summary/last3h`, method: 'GET', ...rest });
};

export const getEnergySummaryTopTenSites = async ({ ...rest }) => {
  return fetchAPI({ url: `/energy_summary/top10`, method: 'GET', ...rest });
};

export const getPowerStatus = async ({ ...rest }) => {
  return fetchAPI({ url: `/power_status`, method: 'GET', ...rest });
};
