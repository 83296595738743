import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import AutomationLogDetails from '../../components/automation-log-details';
import PageHeader from '../../components/page-header';
import { useError } from '../../hooks/useError';
import { getAutomationLogDetails } from '../../store/features/automationsSlice';
import { AutomationLogDetailContainerWrapper } from '../../styles/pages/automation-log-detail.styled';

const AutomationLogDetail = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();

  const { showErrorToast } = useError();

  useEffect(() => {
    dispatch(getAutomationLogDetails({ automationLogId: id })).catch(error => {
      showErrorToast({ error, default_message: t('ERROR_WHILE_FETCHING_AUTOMATION_LOG_DETAILS') });
    });
  }, []);

  return (
    <AutomationLogDetailContainerWrapper className="flex-column flex-1">
      <PageHeader
        names={[
          { text: t('PLATFORMS'), onClick: () => {} },
          { text: t('AUTOMATION_LOG'), path: '/platforms/automation-logs' },
          { text: id, onClick: () => {} },
        ]}
        wrapperClassName="pxy-6 automation-log-header"
      />
      <AutomationLogDetails />
    </AutomationLogDetailContainerWrapper>
  );
};

export default AutomationLogDetail;
