import classNames from 'classnames';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import HidePassword from '../../assets/icons/hide-password.svg';
import ShowPassword from '../../assets/icons/show-password.svg';
import Button from '../../components/common/button/button';
import PhoneInput from '../../components/common/phone-input';
import { countMatchesInRegex, onlyNumbers } from '../../helpers/utils';
import { useError } from '../../hooks/useError';
import { useQuery } from '../../hooks/useQuery';
import { addToast } from '../../store/features/toastSlice';
import { activateUser, getPasswordRequirements, getRequiredField } from '../../store/features/userSlice';
import AuthContainer from '../common/auth-container';
import CustomTooltip from '../common/tooltip-new';
import InviteExpired from './invite-expired';

const upperCaseRegex = /[A-Z]/g;
const lowerCaseRegex = /[a-z]/g;
const numberCaseRegex = /[0-9]/g;
const specialCharacterRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g;

const AccountActivation = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { showErrorToast } = useError();

  const query = useQuery();
  const cleanedToken = query.get('token');
  const tokenData = cleanedToken ? JSON.parse(window.atob(cleanedToken.split('.')[1])) : {};
  const isTokenExpired = moment().unix() > tokenData?.exp;

  const [user, setUser] = useState({ employee_id: '', phone: '', country: '+44', password: '', confirmPassword: '' });
  const [error, setError] = useState({ employee_id: false });
  const [showPassword, setShowPassword] = useState({ newPassword: false, confirmPassword: false });
  const [loading, setLoading] = useState(false);
  const [fetchingRequiredFields, setFetchingRequiredFields] = useState(false);
  const [requiredFields, setRequiredFields] = useState({});
  const [errorText, setErrorText] = useState('');
  const [passwordRequirementError, setPasswordRequirementError] = useState({
    minumumPasswordLengthError: false,
    upperCaseError: false,
    lowerCaseError: false,
    numberError: false,
    specialCharacterError: false,
  });
  const [passwordRequiremets, setPasswordRequirements] = useState({
    min_length: 16,
    min_digits: 1,
    min_uppercase: 1,
    min_special: 1,
    min_lowercase: 1,
  });

  const fetchPasswordRequirements = async authenticationToken => {
    dispatch(getPasswordRequirements(authenticationToken))
      .then(data => setPasswordRequirements(data))
      .catch(e => setPasswordRequirements({}));
  };

  const checkPasswordRequirements = () => {
    const { password } = user;
    const { min_length, min_uppercase, min_lowercase, min_digits, min_special } = passwordRequiremets;

    const minumumPasswordLengthError = password ? password?.length < min_length : true;
    const upperCaseError = min_uppercase > 0 ? countMatchesInRegex(upperCaseRegex, password) < min_uppercase : false;
    const lowerCaseError = min_lowercase > 0 ? countMatchesInRegex(lowerCaseRegex, password) < min_lowercase : false;
    const numberError = min_digits > 0 ? countMatchesInRegex(numberCaseRegex, password) < min_digits : false;
    const specialCharacterError =
      min_special > 0 ? countMatchesInRegex(specialCharacterRegex, password) < min_special : false;

    setPasswordRequirementError({
      minumumPasswordLengthError,
      upperCaseError,
      lowerCaseError,
      numberError,
      specialCharacterError,
    });
    return minumumPasswordLengthError || upperCaseError || lowerCaseError || numberError || specialCharacterError;
  };

  const passwordRequirementTooltip = () => {
    const { min_length, min_uppercase, min_lowercase, min_digits, min_special } = passwordRequiremets;

    return (
      <div className="flex-column px-4 py-2">
        <div className="flex items-center">
          <span className="inter-500-text natural-500-text font-12">{t('PASSWORD_MUST')}:</span>
        </div>
        <div className="flex-column mt-1">
          {passwordRequirementError.minumumPasswordLengthError && (
            <span className="inter-400-text natural-400-text font-12">
              {t('HAVE')} {min_length} {t('CHARACTERS')}
            </span>
          )}
          {passwordRequirementError.upperCaseError && (
            <span className="inter-400-text natural-400-text font-12">
              {t('HAVE')} {min_uppercase} {t('UPPER_CASE')}
            </span>
          )}
          {passwordRequirementError.lowerCaseError && (
            <span className="inter-400-text natural-400-text font-12">
              {t('HAVE')} {min_lowercase} {t('LOWER_CASE')}
            </span>
          )}
          {passwordRequirementError.numberError && (
            <span className="inter-400-text natural-400-text font-12">
              {t('HAVE')} {min_digits} {t('NUMBER')}
            </span>
          )}
          {passwordRequirementError.specialCharacterError && (
            <span className="inter-400-text natural-400-text font-12">
              {t('HAVE')} {min_special} {t('SPECIAL_CHARACTER')}
            </span>
          )}
        </div>
      </div>
    );
  };

  const onActivate = async () => {
    const { phone, country, password, confirmPassword, employee_id } = user;

    if (requiredFields.PHONE && !phone) {
      setError({ phone: true });
      setErrorText(t('PLEASE_ENTER_PHONE_NUMBER'));
      return;
    }

    if (requiredFields.PHONE && phone && phone.length < 12) {
      setError({ phone: true });
      setErrorText(t('PHONE_NUMBER_IS_NOT_VALID'));
      return;
    }

    if (requiredFields.EMPLOYEE_ID && !employee_id) {
      setError({ employee_id: true });
      setErrorText(t('PLEASE_ENTER_EMPLOYEE_ID'));
      return;
    }

    if (checkPasswordRequirements()) {
      setError({ password: true });
      setErrorText(t('PASSWORD_MINIMUM_REQUIREMENTS'));
      return;
    }

    if (password !== confirmPassword) {
      setError({ password: true });
      setErrorText(t('PASSWORD_NOT_MATCH'));
      return;
    }

    setError({});
    setErrorText('');
    setLoading(true);
    const clearedPhoneNumber = phone?.replaceAll(' ', '')?.replaceAll('-', '');
    const userPhone = country + clearedPhoneNumber;

    const phoneRequest = requiredFields.PHONE ? { phone: userPhone } : {};
    const employeeIdRequest = requiredFields.EMPLOYEE_ID ? { employee_id: employee_id } : {};

    dispatch(activateUser({ token: cleanedToken, request: { password, ...phoneRequest, ...employeeIdRequest } }))
      .then(() => {
        dispatch(addToast({ error: false, text: t('USER_ACTIVATED') }));
        navigate('/login');
      })
      .catch(error => {
        setLoading(false);
        showErrorToast({ error, default_message: t('ERROR_WHILE_ACTIVATING_USER') });
      });
  };

  useEffect(() => {
    if (cleanedToken && !isTokenExpired) {
      setFetchingRequiredFields(true);
      dispatch(getRequiredField(cleanedToken))
        .then(data => {
          const requiredFields = (data || [])
            .filter(item => item.is_required_in_invite)
            .reduce((acc, item) => {
              acc[item.field] = item;
              return acc;
            }, {});
          setRequiredFields(requiredFields);
          setFetchingRequiredFields(false);
        })
        .catch(() => {
          setRequiredFields({});
          setFetchingRequiredFields(false);
        });
      fetchPasswordRequirements(cleanedToken);
    }
  }, [cleanedToken, isTokenExpired]);

  return (
    <AuthContainer>
      <AccountActivationWrapper className="flex flex-column items-center m-auto">
        {isTokenExpired ? (
          <InviteExpired />
        ) : fetchingRequiredFields ? (
          <></>
        ) : (
          <Fragment>
            <label className="inter-700-text natural-900-text font-28">{t('ACTIVATE_YOUR_ACCOUNT')}</label>
            <label className="inter-400-text natural-500-text text-center mt-2">
              {t('DETAILS_TO_ACTIVATE_ZERVIO_ACCOUNT')}
            </label>
            <div className="flex-column row-gap-6 mt-10 w-full">
              {errorText && (
                <div className="flex items-center error-message-alert pxy-1 w-full">
                  <label className="inter-500-text error-text">{errorText}</label>
                </div>
              )}
              {requiredFields?.PHONE && (
                <div className="flex-column">
                  <label className="inter-500-text natural-900-text mb-2">{t('PHONE_NUMBER')}</label>
                  <div className="w-full input-container">
                    <PhoneInput
                      selectedCountry={user.country}
                      setSelectedCountry={country => setUser({ ...user, country, phone: '' })}
                      phone={user.phone}
                      setPhone={number => {
                        setUser({ ...user, phone: number });
                      }}
                      className={classNames('w-full')}
                      error={error.phone}
                    />
                  </div>
                </div>
              )}
              {requiredFields?.EMPLOYEE_ID && (
                <div className="flex-column">
                  <label className="inter-500-text natural-900-text mb-2">{t('EMPLOYEE_NUMBER')}</label>
                  <input
                    autoComplete="turnoff"
                    className={classNames('input', error?.employee_id && 'error-border')}
                    onChange={e => {
                      if (onlyNumbers.test(e.target.value) || !e.target.value.trim()) {
                        setUser({ ...user, employee_id: e.target.value });
                        setError({ ...error, employee_id: false });
                      }
                    }}
                    placeholder={t('TYPE_EMPLOYEE_NUMBER')}
                    value={user?.employee_id}
                  />
                </div>
              )}
              <div className="flex-column w-full">
                <label className="inter-500-text natural-900-text mb-2">{t('PASSWORD')}</label>
                <div className="password-requirement-tooltip">
                  <CustomTooltip
                    id="password-error-tooltip"
                    place={'right'}
                    isOpen={
                      !!(
                        error.password &&
                        (passwordRequirementError.minumumPasswordLengthError ||
                          passwordRequirementError.upperCaseError ||
                          passwordRequirementError.lowerCaseError ||
                          passwordRequirementError.numberError ||
                          passwordRequirementError.specialCharacterError)
                      )
                    }
                    content={passwordRequirementTooltip()}>
                    <div className="input-container relative">
                      <input
                        autoComplete="off"
                        placeholder="Enter password"
                        value={user.password}
                        name="password"
                        className={classNames('input radius-2 password-input w-full', error.password && 'error-border')}
                        onChange={e => {
                          setError({ ...error, password: false });
                          setErrorText('');
                          setUser({ ...user, password: e.target.value });
                        }}
                        type={showPassword.newPassword ? 'text' : 'password'}
                      />
                      <img
                        className="show-password"
                        src={showPassword.newPassword ? HidePassword : ShowPassword}
                        alt="show password"
                        onClick={() => setShowPassword({ ...showPassword, newPassword: !showPassword.newPassword })}
                      />
                    </div>
                  </CustomTooltip>
                </div>
              </div>
              <div className="flex-column w-full">
                <label className="inter-500-text natural-900-text mb-2">{t('CONFIRM_PASSWORD')}</label>
                <div className="input-container relative">
                  <input
                    autoComplete="off"
                    placeholder="Confirm password"
                    value={user.confirmPassword}
                    name="confirm-password"
                    className={classNames('input radius-2 password-input w-full', error.password && 'error-border')}
                    onChange={e => {
                      setError({ ...error, password: false });
                      setErrorText('');
                      setUser({ ...user, confirmPassword: e.target.value });
                    }}
                    type={showPassword.confirmPassword ? 'text' : 'password'}
                  />
                  <img
                    className="show-password"
                    src={showPassword.confirmPassword ? HidePassword : ShowPassword}
                    alt="show password"
                    onClick={() => setShowPassword({ ...showPassword, confirmPassword: !showPassword.confirmPassword })}
                  />
                </div>
              </div>
            </div>
            <Button
              label="Activate"
              className="mt-10 primary"
              size="large"
              borderRadius="100px"
              onClick={onActivate}
              disabled={loading}
            />
          </Fragment>
        )}
      </AccountActivationWrapper>
    </AuthContainer>
  );
};

const AccountActivationWrapper = styled.div`
  width: 360px;

  #password-error-tooltip {
    padding: 0 !important;
  }

  .input-container {
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;
  }

  .password-input {
    padding-right: 40px;
  }

  .show-password {
    position: absolute;
    width: 24px;
    height: 24px;
    right: 16px;
    top: 12px;
    cursor: pointer;
  }

  .error-phone {
    .phone-input {
      border: 1px solid ${({ theme }) => theme.primary} !important;
    }
  }

  .password-error {
    width: 100%;
    border-radius: 8px;

    img {
      width: 16px;
      height: 16px;
    }
  }
`;

export default AccountActivation;
