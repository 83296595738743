import api from '../store/services/index';

const fetchAPI = async ({ url, method, setDataFunc, ...rest }) => {
  try {
    const { data } = await api({ url, method, ...rest });
    setDataFunc && setDataFunc(data);
    return data;
  } catch (error) {
    setDataFunc && setDataFunc(null);
    return Promise.reject(error);
  }
};

export const getInvoiceDetails = async ({ invoice_id, ...rest }) => {
  return fetchAPI({ url: `/invoices/${invoice_id}`, method: 'GET', ...rest });
};

export const updateInvoiceIntegration = async ({ invoice_id, integration_id, ...rest }) => {
  return fetchAPI({ url: `/invoices/${invoice_id}/integrations/${integration_id}`, method: 'POST', ...rest });
};
