import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { ReactComponent as CrossIcon } from '../../assets/icons/toast-close.svg';
import Button from '../../components/common/button/button';
import Dropdown from '../../components/common/select-dropdown';
import { OrganisationContext } from '../../context/organisationContext';
import { initModal } from '../../helpers/utils';
import { useError } from '../../hooks/useError.js';
import { createIntegration, getConnectors } from '../../store/features/integrationsSlice';
import { addToast } from '../../store/features/toastSlice';
import { AddIntegrationWrapper } from '../modal.styled.js';

const AddIntegration = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { showErrorToast } = useError();

  const { setModal } = useContext(OrganisationContext);

  const [integration, setIntegration] = useState({});
  const [error, setError] = useState({ name: false, provider: false, providerType: false, description: false });
  const [loading, setLoading] = useState(false);
  const [connectors, setConnectors] = useState([]);

  useEffect(() => {
    dispatch(getConnectors())
      .then(data => {
        const connectors = data.map(connector => ({ ...connector, label: connector.name, value: connector.id }));
        setConnectors(connectors);
      })
      .catch(error => {
        setConnectors([]);
        showErrorToast({ error, default_message: t('ERROR_FETCHING_CONNECTORS') });
      });
  }, []);

  const checkIntegrationErrors = () => {
    if (
      !integration?.name?.trim() ||
      !integration?.description?.trim() ||
      !integration?.provider?.id ||
      !integration?.connector?.id
    ) {
      setError({
        name: !integration?.name?.trim(),
        provider: !integration?.provider?.id,
        providerType: !integration?.connector?.id,
        description: !integration?.description?.trim(),
      });
      const errorFields = Object.entries(error)
        .filter(([_, value]) => value)
        .map(([key]) => key)
        .join(', ');
      dispatch(
        addToast({
          error: true,
          text: errorFields
            ? `Please fill integration ${errorFields}`
            : t('FILL_INTEGRATION_NAME_DESCRIPTION_PROVIDER'),
        }),
      );
      return true;
    }
    return false;
  };

  const onDone = async () => {
    if (loading) {
      return;
    }
    if (checkIntegrationErrors()) {
      return;
    }

    setLoading(true);
    const request = {
      name: integration.name,
      description: integration.description,
      connector: { id: integration.connector.id },
      is_enabled: false,
      is_default: false,
    };
    dispatch(createIntegration({ request }))
      .then(({ data }) => {
        setLoading(false);
        setModal(initModal);
        navigate(`/platforms/integrations/integration-details/${data?.id}`);
        dispatch(
          addToast({
            error: false,
            text: t('INTEGRATION_ADDED_SUCCESSFULLY'),
          }),
        );
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const getProviderOptions = provider => {
    if (!provider) {
      return [];
    }
    return provider.connectors?.map(connector => ({
      ...connector,
      label: connector.name,
      value: connector.id,
      icon: null,
    }));
  };

  return (
    <CSSTransition appear classNames="popup-fade" in timeout={300}>
      <AddIntegrationWrapper className="flex-column px-12 py-8">
        <div className="flex justify-between ">
          <p className="inter-600-text natural-900-text font-20">{t('ADD_INTEGRATION')}</p>
          <CrossIcon width={24} height={24} className="cursor" onClick={() => setModal(initModal)} />
        </div>
        <div className="w-full flex-column mt-6">
          <label className="inter-500-text natural-900-text mb-2">{t('NAME')}</label>
          <input
            autoComplete="turnoff"
            className={`input ${error.name && 'error-border'}`}
            onChange={e => {
              setIntegration({ ...integration, name: e.target.value });
              setError({ ...error, name: false });
            }}
            placeholder={t('ENTER_INTEGRATION_NAME')}
            value={integration.name || ''}
          />
        </div>
        <div className="w-full flex-column mt-6">
          <label className="inter-500-text natural-900-text mb-2">{t('DESCRIPTION')}</label>
          <textarea
            className={`input textarea description-input ${error.description && 'error-border'}`}
            onChange={e => {
              setIntegration({ ...integration, description: e.target.value });
              setError({ ...error, description: false });
            }}
            placeholder={t('DESCRIPTION_PLACEHOLDER')}
            rows={4}
            value={integration.description || ''}
          />
        </div>
        <div className="w-full flex-column mt-6">
          <label className="inter-500-text natural-900-text mb-2">{t('PROVIDER_TYPE')}</label>
          <Dropdown
            className={`provider-selector ${error.provider && 'error-border'}`}
            onChange={option => {
              setIntegration({ ...integration, provider: option, connector: null });
              setError({ ...error, provider: false });
            }}
            options={connectors}
            placeholder={t('SELECT')}
            error={error.provider}
            value={connectors?.find(relation => relation.id === integration.provider?.id)}
          />
        </div>
        <div className="w-full flex-column mt-6">
          <label className="inter-500-text natural-900-text mb-2">{t('PROVIDERS')}</label>
          <Dropdown
            className={`provider-selector ${error.provider && 'error-border'}`}
            onChange={option => {
              setIntegration({ ...integration, connector: option });
              setError({ ...error, connector: false });
            }}
            options={getProviderOptions(integration.provider)}
            placeholder={t('SELECT')}
            error={error.connector}
            value={integration.connector}
          />
        </div>
        <div className="flex col-gap-6 justify-center mt-10 w-full">
          <Button
            className={`default flex-1 ${loading && 'disabled'}`}
            label={t('CANCEL')}
            disabled={loading}
            onClick={() => setModal(initModal)}
            secondary={true}
            size="large"
            borderRadius="100px"
          />
          <Button
            className={`primary flex-1 ${loading && 'disabled'}`}
            label={t('ADD')}
            disabled={loading}
            onClick={onDone}
            primary={true}
            size="large"
            borderRadius="100px"
          />
        </div>
      </AddIntegrationWrapper>
    </CSSTransition>
  );
};

export default AddIntegration;
