import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { useTheme } from 'styled-components';
import { getFormattedNumberStyle } from '../../helpers/utils';
import { usePowerStatus } from '../../hooks/useDashboard';
import { useError } from '../../hooks/useError';
import HalfRadicalChart from '../common/charts/half-radical-chart';
import Label from '../common/label';

const formatSystemSize = system_size => {
  // format if it is in GigaWatt, MegaWatt, KiloWatt
  if (system_size >= 1000000000) {
    return `${getFormattedNumberStyle(system_size / 1000000000, 'decimal', 2, 2)} GW`;
  } else if (system_size >= 1000000) {
    return `${getFormattedNumberStyle(system_size / 1000000, 'decimal', 2, 2)} MW`;
  } else if (system_size >= 1000) {
    return `${getFormattedNumberStyle(system_size / 1000, 'decimal', 2, 2)} KW`;
  } else {
    return `${getFormattedNumberStyle(system_size, 'decimal', 2, 2)} W`;
  }
};

const EnergyGeneration = () => {
  const theme = useTheme();
  const { data, isFetching, error } = usePowerStatus({});

  useError({ error, default_message: 'Error fetching energy generation' });

  const { pv_power, system_size } = data || {};
  const percentagePower = ((pv_power / system_size) * 100).toFixed(0);
  const formattedSystemSize = formatSystemSize(system_size);

  return (
    <div className="flex flex-col px-6 py-4 gap-y-4 bg-white rounded-xl min-h-[250px]">
      <Label className="inter-600-text font-18">Energy Generation</Label>
      {isFetching && <Skeleton containerClassName="h-full tracking-[1px]" height={'100%'} />}
      {!isFetching && (
        <HalfRadicalChart data={[{ value: percentagePower }]}>
          <text
            x="50%"
            y="40%"
            className="inter-500-text font-12"
            style={{ fill: theme.natural_500 }}
            width={'100%'}
            textAnchor="middle">
            System size
          </text>
          <text
            x="50%"
            y="55%"
            className="inter-600-text font-20"
            style={{ fill: theme.natural_500 }}
            width={'100%'}
            textAnchor="middle">
            {formattedSystemSize}
          </text>
          <text
            x="50%"
            y="75%"
            className="inter-700-text font-28"
            style={{ fill: theme.primary_500 }}
            width={'100%'}
            textAnchor="middle">
            {percentagePower}%
          </text>
        </HalfRadicalChart>
      )}
    </div>
  );
};

export default EnergyGeneration;
