import { createSlice } from '@reduxjs/toolkit';
import api from '../services';

export const automationsSlice = createSlice({
  name: 'automations',
  initialState: {
    automationsListData: {},
    automationDetails: [],
    automationLogsList: [],
    automationLogDetails: null,
    automationLogDetailsList: [],
    userList: [],
  },
  reducers: {
    setAutomationsListData: (state, { payload }) => {
      const { data, merge } = payload || {};
      if (merge) {
        state.automationsListData = { ...data, content: [...state.automationsListData?.content, ...data?.content] };
      } else {
        state.automationsListData = data;
      }
    },
    setAutomationsDetails: (state, { payload }) => {
      state.automationDetails = payload;
    },
    setAutomationLogsList: (state, { payload }) => {
      const { data, merge } = payload || {};
      if (merge) {
        state.automationLogsList = { ...data, content: [...state.automationLogsList.content, ...data.content] };
      } else {
        state.automationLogsList = data;
      }
    },
    setAutomationLogDetails: (state, { payload }) => {
      state.automationLogDetails = { ...payload };
    },
    setAutomationDetailsLog: (state, { payload }) => {
      const { data, merge } = payload || {};
      if (merge) {
        state.automationLogDetailsList = {
          ...data,
          content: [...state.automationLogDetailsList.content, ...data.content],
        };
      } else {
        state.automationLogDetailsList = data;
      }
    },
    setUserList: (state, { payload }) => {
      state.userList = payload;
    },
    removeAutomationFromList: (state, { payload }) => {
      state.automationsListData = {
        ...state.automationsListData,
        content: [...state.automationsListData.content.filter(automation => automation.id !== payload.id)],
      };
    },
    updateAutomationStateInList: (state, { payload }) => {
      state.automationsListData = {
        ...state.automationsListData,
        content: [
          ...state.automationsListData.content.map(automation =>
            automation.id === payload.id ? { ...automation, is_enabled: !automation.is_enabled } : { ...automation },
          ),
        ],
      };
    },
  },
});

export const getUsersList = payload => async dispatch => {
  try {
    const { data } = await api.get(`/users`, { params: payload.params });
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAutomationsList =
  ({ params, merge }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/automations`, { params: params });
      dispatch(setAutomationsListData({ data, merge }));
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getAutomationsDetails = payload => async dispatch => {
  try {
    const { data } = await api.get(`/automations/${payload}`);
    dispatch(setAutomationsDetails(data));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAutomationLogs =
  ({ params, merge }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/automation_logs`, { params: params });
      dispatch(setAutomationLogsList({ data, merge }));
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getAutomationLogDetails = payload => async dispatch => {
  try {
    const { data } = await api.get(`/automation_logs/${payload.automationLogId}`);
    dispatch(setAutomationLogDetails(data));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const retryAutomationLog = payload => async dispatch => {
  try {
    const { automationLogId } = payload;
    const { data } = await api.post(`/action_log/${automationLogId}/retry`);
    dispatch(getAutomationLogs(payload));
    return Promise.resolve({ data });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const cancelAutomationLogs = payload => async dispatch => {
  try {
    const { automationLogId } = payload;
    const { data } = await api.post(`/action_logs/${automationLogId}/cancel`);
    dispatch(getAutomationLogs(payload));
    return Promise.resolve({ data });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAutomationDetailsLogs = payload => async dispatch => {
  try {
    const { id: automation_id, params, merge } = payload;
    const { data } = await api.get(`/automation_logs?automation_id=${automation_id}`, { params: params });
    dispatch(setAutomationDetailsLog({ data, merge }));
    return Promise.resolve({ data });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateAutomationDetails = payload => async dispatch => {
  try {
    const { automationID, request } = payload;
    const { data } = await api.put(`/automations/${automationID}`, request);
    dispatch(getAutomationsDetails(automationID));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createAutomation = payload => async dispatch => {
  try {
    const { data } = await api.post(`/automations`, payload.request);
    return Promise.resolve({ data });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateAutomationStatus = payload => async dispatch => {
  try {
    const { automationID, request } = payload;
    const { data } = await api.put(`/automations/${automationID}/state`, request);
    dispatch(getAutomationsDetails(automationID));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteAutomation = payload => async dispatch => {
  try {
    const { automationID } = payload;
    const { data } = await api.delete(`/automations/${automationID}`);
    dispatch(getAutomationsList({ params: { search: '', page: 0, size: 15 } }));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getEventTypes =
  ({ params = {} }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/event_types`, { params });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const updateEventType =
  ({ automation_id, request }) =>
  async dispatch => {
    try {
      const { data } = await api.put(`/automations/${automation_id}/event_type`, request);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getAutomationActions =
  ({ params = {} }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/actions`, { params });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getActionIntegrations =
  ({ action_type = '' }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/actions/${action_type}/integrations`);
      return Promise.resolve({ data });
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getConnectorConfig =
  ({ connector_id }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/connector/${connector_id}/config`);
      return Promise.resolve({ data });
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getIntegrationActionConfig =
  ({ actionType, integration_id }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/integrations/${integration_id}/action/${actionType}/config`);
      return Promise.resolve({ data });
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const addAutomationActions =
  ({ automation_id, request }) =>
  async dispatch => {
    try {
      const { data } = await api.post(`/automations/${automation_id}/actions`, request);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const updateAutomationAction =
  ({ automation_id, request, action_id }) =>
  async dispatch => {
    try {
      const { data } = await api.put(`/automations/${automation_id}/actions/${action_id}`, request);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getTemplateListOptions =
  ({ integration_id, actionType, paramId, params = {} }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/integrations/${integration_id}/action/${actionType}/lists/${paramId}`, {
        params,
      });
      return Promise.resolve({ data });
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getLexiconListOptions =
  ({ lexicon, params = {} }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/lexicons/${lexicon}/values`, {
        params,
      });
      return Promise.resolve({ data });
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getAttachmentOptions = () => async dispatch => {
  try {
    const { data } = await api.get(`/evidence_rules`);
    return Promise.resolve({ data });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteAutomationAction =
  ({ automation_id, action_id }) =>
  async dispatch => {
    try {
      const { data } = await api.delete(`/automations/${automation_id}/actions/${action_id}`);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const {
  setAutomationsListData,
  setAutomationLogsList,
  setAutomationLogDetails,
  setAutomationsDetails,
  setAutomationDetailsLog,
  setUserList,
  removeAutomationFromList,
  updateAutomationStateInList,
} = automationsSlice.actions;
export default automationsSlice.reducer;
