import moment from 'moment';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { useTheme } from 'styled-components';
import { ReactComponent as RefreshIcon } from '../../assets/icons/retry-new-icon.svg';
import { getFormattedNumberStyle } from '../../helpers/utils';
import { useEnergySummaryLastThreeHours } from '../../hooks/useDashboard';
import { useError } from '../../hooks/useError';
import LineChartUI from '../common/charts/line-chart';
import Label from '../common/label';

const generateDataForThreeHourReading = data => {};

const ThreeHourReading = () => {
  const theme = useTheme();
  const { data, isFetching, error } = useEnergySummaryLastThreeHours({});

  useError({ error, default_message: 'Error fetching three hour reading' });

  const readingKeys = [
    { dataKey: 'solar_generation', name: 'Solar Generation', stroke: theme.pink_100, strokeWidth: 2 },
    { dataKey: 'export', name: 'Export', stroke: theme.yellow_100, strokeWidth: 2 },
    { dataKey: 'ev_charging', name: 'EV charging', stroke: theme.success_100, strokeWidth: 2 },
  ];

  const xAxisFormatter = (value, _dataIndex, _indexData = null, fromTooltip = false) => {
    if (fromTooltip) {
      return moment.unix(value).format('dddd, Do of MMMM HH:mm');
    }
    return moment.unix(value).format('HH:mm');
  };

  if (isFetching) {
    return (
      <div className="flex flex-col px-6 py-4 bg-white rounded-xl min-h-[350px] max-h-[420px] flex-1">
        <Skeleton containerClassName="h-full tracking-[1px]" height={'100%'} />
      </div>
    );
  }

  return (
    <div className="flex flex-col px-6 py-4 gap-y-3 bg-white rounded-xl min-h-[350px] max-h-[420px] flex-1">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-x-4">
          <div className="flex items-center gap-x-1">
            <RefreshIcon className="primary-text scale-x-[-1]" />
            <Label className="inter-400-text primary-text">3 hours before</Label>
          </div>
          <div className="flex items-center gap-x-1">
            <Label className="inter-400-text primary-text">Back to now</Label>
            <RefreshIcon className="primary-text" />
          </div>
        </div>
        <Label className="inter-400-text">3 hours</Label>
      </div>
      <LineChartUI
        data={data}
        xAxisDataKey="reading_timestamp"
        linesArray={readingKeys}
        yAxisFormatter={value => `${getFormattedNumberStyle(value, 'decimal', 2, 2)} kWh`}
        xAxisFormatter={xAxisFormatter}
      />
    </div>
  );
};

export default ThreeHourReading;
