import classNames from 'classnames';
import { motion } from 'framer-motion';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ReactComponent as BankCardIcon } from '../../assets/icons/bank-card-icon.svg';
import { ReactComponent as BankIcon } from '../../assets/icons/bank-icon.svg';
import { ReactComponent as InfoIcon } from '../../assets/icons/disabled_icon_default.svg';
import { ReactComponent as HorizontalDotsIcon } from '../../assets/icons/HorizontalDots.svg';
import {
  capitalize,
  capitalizeFirstLetterOfWords,
  formatText,
  getContactValueByType,
  getFormattedDateV2,
  getFormattedNumber,
} from '../../helpers/utils';
import IconContainer from '../common/icon-container';
import Label from '../common/label';
import MenuV2 from '../common/menu/menu-v2';
import Status from '../common/status';
import CustomTooltip from '../common/tooltip-new';

const TransactionListItem = ({ transaction = {}, index = 0 }) => {
  const { t } = useTranslation();

  const {
    id: transactionId,
    account,
    amount,
    status,
    created_on,
    transaction_type,
    transaction_number,
    payment_method,
  } = transaction;

  const { account_number, contact, billing_email, contact_type } = account || {};
  const { type, last_4_digits, brand } = payment_method || {};
  const { name: account_holder_name, phones } = contact || {};
  const isCompany = contact_type === 'COMPANY';
  const mobile = getContactValueByType(phones, 'contact_type', 'MOBILE', 'contact_value') || '';
  const landline = getContactValueByType(phones, 'contact_type', 'LANDLINE', 'contact_value') || '';

  return (
    <TransactionListItemWrapper
      initial={{ opacity: 0.3, y: 10 }}
      animate={{ opacity: 1, y: 0, transition: { duration: 0.9 } }}
      key={transactionId}
      className={classNames('data-container py-1 cursor', index > 0 && 'border-top')}>
      <div className="flex-column row-gap-2 overflow-hidden py-4">
        <Label className="flex items-center inter-400-text">
          {transaction_number || t('NO_TYPE', { type: formatText('transaction number') })}
        </Label>
      </div>
      <div className="flex-column row-gap-1 overflow-hidden py-4">
        <Label className="flex items-center inter-400-text" hasPlaceholder={!account_holder_name}>
          {account_holder_name || t('NO_TYPE', { type: formatText('name') })}
        </Label>
        <Label className="flex items-center inter-400-text natural-500-text font-12" hasPlaceholder={!account_number}>
          Account number: {account_number || t('NO_TYPE', { type: formatText('account number') })}
        </Label>
        <Label
          className="flex items-center inter-400-text natural-500-text font-12"
          hasPlaceholder={isCompany ? !landline : !mobile}>
          {isCompany ? landline || t('NO_TYPE', { type: 'landline' }) : mobile || t('NO_TYPE', { type: 'phone' })}
        </Label>
        <Label className="flex items-center inter-400-text natural-500-text font-12" hasPlaceholder={!billing_email}>
          {billing_email || t('NO_TYPE', { type: formatText('billing email') })}
        </Label>
      </div>
      <div className="flex-column row-gap-2 overflow-hidden py-4">
        <Label className="flex items-center inter-400-text" hasPlaceholder={!created_on}>
          {getFormattedDateV2({ dateInUnix: created_on }) || t('NO_TYPE', { type: formatText('create date') })}
        </Label>
      </div>
      <div className="flex-column row-gap-2 overflow-hidden py-4">
        <Label className="flex items-center inter-400-text" hasPlaceholder={!transaction_type}>
          {capitalize(formatText(transaction_type)) || t('NO_TYPE', { type: formatText('type') })}
        </Label>
      </div>
      <div className="flex items-start inter-400-text py-4 natural-900-text">
        <IconContainer
          iconContainerClassname="mr-2 p-0 items-start"
          Icon={type === 'CARD' ? BankCardIcon : BankIcon}
          iconColor="natural_900"
          backgroundColor="transparent"
          iconWidth={20}
          iconHeight={20}
        />
        {type === 'CARD' ? (
          <Label className="flex inter-400-text natural-900-text">ending in {last_4_digits}</Label>
        ) : (
          <Label className="flex inter-400-text natural-900-text">{t('BANK_TRANSFER')}</Label>
        )}
      </div>
      <div className="flex-column row-gap-2 overflow-hidden py-4">
        <Label className="flex items-center inter-400-text">{getFormattedNumber(amount)}</Label>
      </div>
      <div className="flex col-gap-2 overflow-hidden py-4">
        <Status
          status={status}
          statusText={capitalizeFirstLetterOfWords(status?.toLowerCase())}
          statusTextClassName="inter-400-text font-12"
          statusWrapperClassName=""
        />
        {status === 'FAILURE' && (
          <CustomTooltip
            wrapperClassName="status-tooltip"
            place="bottom"
            content={<span className="inter-400-text font-12">Some text about failure reason</span>}
            id={`tooltip-${transactionId}`}>
            <IconContainer
              Icon={InfoIcon}
              iconHeight={14}
              iconWidth={14}
              iconColor="greyText2"
              backgroundColor="transparent"
              iconContainerClassname="justify-start p-0"
            />
          </CustomTooltip>
        )}
      </div>
      <MenuV2
        portal={true}
        menuList={[
          {
            name: t('DETAILS'),
          },
        ]}
        Icon={HorizontalDotsIcon}
        menuWrapperClassname="flex"
        menuClassName="property-menu"
        iconClassname="my-4"
      />
    </TransactionListItemWrapper>
  );
};

const TransactionListItemWrapper = styled(motion.div)`
  min-height: 120px;

  &:hover {
    background: ${({ theme }) => theme.natural_50};
  }

  .address-tooltip {
    padding: 8px 12px;
  }
  .status-tooltip {
    &:hover {
      .icon {
        color: ${({ theme }) => theme.error_500};
      }
    }
  }
`;

export default TransactionListItem;
